<template>
    <span class="badge " :class="statusStyling(status)">
        {{ statusDisplay(status) }}
    </span>
</template>

<script>
export default {
    name: "GigTagByStatus",
    props:{
        status: String
    },
    methods:{
        statusDisplay(value)
        {
            switch(value) {
                case "UNPUBLISHED":
                    return 'Draft'
                case "REACHED_BILLING_LIMIT":
                    return 'Draft'
                case "CANCELLED":
                    return 'Cancelled'
                case "CONFIRMED":
                    return 'Confirmed'
                case "NEEDS_RESPONSE":
                    return 'Pending Response'
                case "PENDING":
                    return 'Searching for Artists'
                case "SIGNED_OFF":
                    return 'Signed off'
            }
        },
        statusStyling(value)
        {
            switch (value) {
                case 'UNPUBLISHED':
                    return 'bg-indigo-100 text-indigo-800 px-16';
                case "REACHED_BILLING_LIMIT":
                  return 'bg-indigo-100 text-indigo-800 px-16';
                case 'CANCELLED':
                    return 'bg-gray-100 text-gray-800 px-12';
                case 'CONFIRMED':
                    return 'bg-green-500 text-white px-12';
                case 'NEEDS_RESPONSE':
                    return 'bg-red-600 text-white px-6';
                case 'PENDING':
                    return 'bg-yellow-100 text-yellow-800 px-4';
                case 'SIGNED_OFF':
                    return 'bg-black text-white px-12';
            }
        }
    }
}
</script>