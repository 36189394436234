<template>
    <div v-if="modal_open" transition="fadein" class="fixed z-10 inset-0 overflow-y-auto duration-300 ease-in transition-all modal" ref="modal" aria-labelledby="modal-title" role="dialog" aria-modal="true">
        <SubmitMITPayment
            ref="paymentModal"
            :show-modal="showMITModal"
            :MITPublicKey="pubKey"
            :MITClientSecret="MITClientSecret"
            :customerEmail="ryftCustomerEmail"
            @showMITPaymentModal="value => showMITModal = value"
            @formSubmitted="formSubmitted"
            @formFailed="formFailed"
            @cardSubmissionComplete="cardSubmissionComplete"
            @cardNumberIsValid="value => cardNumberIsValid = value"
        />
        <div class="flex items-center justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
            <div class="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" aria-hidden="true">
            </div>
            <span class="hidden sm:inline-block sm:align-middle sm:h-screen" aria-hidden="true">&#8203;</span>
            <transition appear enter-active-class="animate__animated animate__fadeInUp" leave-active-class="animate__animated animate__fadeOutDown">        
                <div class="inline-block align-bottom bg-gp_black-default-500 border border-gp_pink-default-400 rounded-3xl px-4 pt-5 pb-4 text-left shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-lg sm:w-full sm:p-6">
                    <div class="flex justify-end mb-6 text-gp_pink-default-500">
                        <XIcon @click="toggleModal" class="hover:text-gp_pink-default-400 cursor-pointer"/>
                    </div>
                    <div class="grid grid-cols-1">

                        <div v-show="step == 1">
                            <div class="h-auto w-full">
                                <div>
                                    <h3 class="block font-bold text-gp_pink-default-500 uppercase" id="modal-title">GigPig will now handle your artist payments directly</h3>
                                </div>

                                <div class="mt-8 text-white text-justify">
                                    <p class="block my-4">Your Artist fees will now be automatically processed from the card you used to book with. Once the gig is completed and signed off, it will leave your account and the artist invoice will be issued, saving you time on admin.</p>
                                    <p class="block mb-4">GigPig will pay artists 14 days after sign-off, allowing time to resolve any disputes.</p>               
                                    <p class="block mb-6">If you have any questions, <a class="text-gp_pink-default-400 font-bold" href="https://www.gigpig.uk/get-in-touch" target="_blank">get in touch</a> and the team will be happy to help.</p>
                                    <p class="text-xs">* Card fees apply on top of artist fee 1.4% + 10p Visa & Mastercard cards / 1.8% +10p Amex</p>
                                </div>
                                    
                                <div class="w-full mt-10">
                                    <GPButton @click.native="nextStep()"
                                                    color="pink"
                                                    class="w-full justify-between"
                                    >
                                        <span class="font-semibold">Add card details</span>
                                        <ArrowNarrowRightIcon stroke="white" class="w-6 h-6"/>
                                    </GPButton> 
                                </div>
                            </div>
                        </div>
                        <div v-show="step == 2">
                            <div class="h-auto w-full">
                                <div>
                                    <h3 class="block font-bold text-gp_pink-default-500 uppercase" id="modal-title">Save a card</h3>
                                    <p class="uppercase text-sm mt-2 text-gray-100 font-medium">{{ venue.name }}</p> 
                                </div>

                                <div class="mt-6">
                                    <div>
                                        <div class="pt-4 space-y-1 text-sm font-semibold">
                                            <span class="text-gray-100 text-sm font-light">Card nickname</span>
                                            <input type="text" 
                                                    v-model="card_nickname"
                                                    placeholder="e.g. Head office card"
                                                    id="billing_address_line_1" 
                                                    class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5" 
                                                    required 
                                            />
                                        </div>
                                        <p class="text-xs text-gray-200 mt-1 w-64">Naming your card will help distinguish different cards used by your business.</p>
                                    </div>
                                    <div class="mt-6">
                                        <span class="text-gray-100 text-sm font-light">Billing receipt email</span>
                                        <input type="text" 
                                                    v-model="recipient_email"
                                                    placeholder="accounts@myvenue.com"
                                                    id="billing_address_line_1" 
                                                    class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 mt-2" 
                                                    required 
                                            />
                                    </div>

                                    <div class="grid grid-cols-5 mt-6">
                                        <div class="col-span-1 mt-4">
                                            <t-toggle v-model="confirm_charge"/>
                                        </div>
                                        <div class="col-span-4">
                                            <span class="text-gray-200 text-xs text-justify font-light">I confirm my card will be charged by GigPig Ltd for the purpose of token purchases by those with access to my venue.</span>
                                        </div>
                                    </div>
                                    <div class="mt-4">
                                        <span class="text-gray-200 text-xs text-justify font-light">
                                            Your card details will be securely saved for future use by for your venues booking team, so they don’t need access to your card beyond this point.
                                        </span>
                                    </div>
                                </div>
                                    
                                <div class="w-full mt-10">
                                    <GPButton @click.native="addCardDetails()"
                                                    :disabled="isSubmitting"
                                                    :loading="isSubmitting"
                                                    color="pink"
                                                    class="w-full justify-between"
                                    >
                                        <span class="font-semibold">Add card details</span>
                                        <ArrowNarrowRightIcon stroke="white" class="w-6 h-6"/>
                                    </GPButton> 
                                </div>
                            </div>
                        </div>

                        <div v-show="step == 3">
                            <div class="h-auto w-full">
                                <div>
                                    <h3 class="block font-bold text-gp_pink-default-500 uppercase" id="modal-title">Card details successfully added!</h3>
                                </div>

                                <div class="mt-6">
                                    <p class="text-sm mt-2 text-gray-100 font-medium">
                                        Click the return to platform button below to continue.
                                    </p> 
                                </div>

                                <div class="w-full mt-10">
                                    <GPButton @click.native="toggleModal()"
                                                    :disabled="isSubmitting"
                                                    :loading="isSubmitting"
                                                    color="pink"
                                                    class="w-full justify-between"
                                    >
                                        <span class="font-semibold">Return to platform</span>
                                        <ArrowNarrowRightIcon stroke="white" class="w-6 h-6"/>
                                    </GPButton> 
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </transition>        
        </div>
    </div>
</template>

<script>
import { TToggle } from 'vue-tailwind/dist/components'
import { ArrowNarrowRightIcon, XIcon } from "@vue-hero-icons/outline"
import GPButton from '../../../../components/ui/buttons/Button.vue';
import SubmitMITPayment from "@/components/MIT/SubmitMITPayment.vue";
import NProgress from 'nprogress';
import { paymentsMethods, apiMethods } from "@/state/helpers";
import client from "../../../../utils/client"
import { DEFAULT_CURRENCY_ID } from "@/constants/contants";
import { mapState } from 'vuex';

export default
{
    name: "StripePAYGToRyftPAYG", 
    data() {
        return {
            modal_open: false,
            card_nickname: '',
            recipient_email: '',
            venue: [],
            confirm_charge: false,
            same_as_address: false,
            step: 1,
            cardId: null,
            ryftCustomerCreated: false,
            isSubmitting: false,
            currency: DEFAULT_CURRENCY_ID,
            currencies: [],
            base_currency: DEFAULT_CURRENCY_ID,
            MITClientSecret: '',
            ryftCustomerEmail: null,
            pubKey: process.env.VUE_APP_RYFT_PUBLIC_KEY,
            cardNumberIsValid: false,
            billing_address: {
                address_line_1: '',
                address_line_2: '',
                city: '',
                country: '',
                post_code: '',
                company_name: '',
                card_nickname: '',
                recipient_email: ''
            },
            showMITModal: false,
        }
    },
    components: {
        TToggle,
        ArrowNarrowRightIcon, XIcon,
        GPButton,
        SubmitMITPayment
    },
    computed: {
        ...mapState('account', ['venueInfo']),
        currencyIsoCode() {
            return this.currencies.find(f => f.id === this.base_currency).iso_code;
        },
    },
    watch: {
        ryftCustomerCreated() {
            this.initRyftOnboarding();
        },
        async modal_open(value) {
            if (value) {
                const token = await this.$auth.getTokenSilently();
                client.get("currencies", { headers: { Authorization: `Bearer ${token}` } }).then(response => (this.currencies = response.data.data));
            }
        }
    },
    methods: {
        ...paymentsMethods,
        ...apiMethods,
        pickCurrency(value) {
            this.currency = value
        },
        nextStep() {
            this.step = this.step + 1;
        },
        toggleModal() {
            this.confirm_charge = false;
            this.card_nickname = '';
            this.recipient_email = '';
            this.step = 1;
            this.modal_open = !this.modal_open;
        },
        addVenueInfo(venue_info) {
            this.venue = venue_info;
            this.currency = venue_info.currency_id;
            this.toggleModal();
        },
        formSubmitted() {
            NProgress.start();
        },
        formFailed() {
            NProgress.done();
        },
        async addCardBillingDetails() {

            this.isSubmitting = true;

            if (!this.same_as_address && (!this.billing_address.address_line_1  || !this.billing_address.city || !this.billing_address.country || 
                !this.billing_address.post_code) && !this.currency) 
            {
                this.$notify("Please provide all requested details.");
                this.isSubmitting = false;
                return;
            }

            let params = {
                same_as_address: this.same_as_address,
                billing_address: {
                    address_line_1: this.billing_address.address_line_1,
                    address_line_2: this.billing_address.address_line_2,
                    town: this.billing_address.city,
                    country: this.billing_address.country,
                    post_code: this.billing_address.post_code,
                },
                currency: this.currency,
                venue_id: this.venue.id
            };

            await this.updateVenueBillingDetails(params)
                .then(() => {
                    this.isSubmitting = false;
                    this.step = 2;
                })

        },
        addCardDetails() {

            if (!this.card_nickname  || !this.recipient_email)
            {
                this.$notify("Please provide all requested details.");
                return;
            }

            if (!this.confirm_charge)
            {
                this.$notify("Please accept the terms of use to continue.");
                return;
            }

            this.loadRyftSdk()
        },
        loadRyftSdk() {

            this.isSubmitting = true;

            this.createPaymentCard({
                default: 1, 
                email: this.recipient_email,
                venue_id: this.venue.id,
                alias: this.card_nickname,
            }).then(resp => {
                this.cardId = resp.data.id;
                this.ryftCustomerCreated = true;
            }).catch((error) => {
              this.$notify({text: error.response.data.message + ": Please try again or contact support for further assistance.", duration: 5000});
                this.isSubmitting = false;
            })
        },
        initRyftOnboarding() {
            this.createMerchantPaymentSession(
                {
                    amount: 0,
                    verifyAccount: true,
                    currency: this.currencyIsoCode,
                    email: this.recipient_email,
                    metadata: {verifyAccount: true, default: true, cardId: this.cardId.toString(), venueId:this.venue.id },
                    paymentType: 'Unscheduled',
                    venue_id: this.venue.id,
                    venueId:this.venue.id
                }
            ).then((resp) => {
                this.MITClientSecret = resp.data.clientSecret
                this.ryftCustomerEmail = resp.data.customerEmail
                this.showMITModal = true
                this.isSubmitting = false;
            }).catch(() => {
                this.$notify("An error has occurred, please contact GigPig support.");
                this.isSubmitting = false;
            });
        },
        cardSubmissionComplete() {
            this.$notify("Please wait while we verify your payment card information.");
            this.cardSubmissionCheck();
        },
        cardSubmissionCheck() {
        
            setTimeout(() => {
                this.getVenuePaymentCard({ card_id: this.cardId })
                    .then((response) => {
                        if (!response.data.merchant_id || !response.data.initial_payment_id) {
                            this.cardSubmissionCheck();
                        } else {
                            this.togglePAYGMonthly();
                        }
                    })
            }, 2000);
        },
        async togglePAYGMonthly() {

            await this.switchVenueToPAYGMonthly({ venue_id: this.venue.id })
                .then(() => {
                    this.$notify("Card submission completed.");
                    this.$emit('createGig')
                    this.showMITModal = false;
                    this.toggleModal();
                    NProgress.done();
                })
        }
    }
}

</script>