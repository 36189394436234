<template>
  <PageDetails>
    <h1 class="text-2xl font-bold leading-7 text-gray-900 sm:leading-9 sm:truncate">
      Edit Venue {{ isLoading ? "-" : "-" }}
      <span v-if="venue">{{venue.name}}</span>
    </h1>
    <div class="lg:max-w-5xl">

      <VenueTabs :venue-id="venueId" current-route="manage-subscription"/>
      
      <template v-if="isLoading">
        <div class="p-8 flex justify-center items-start">
          <PageSpinner
            tailwind-diameter="8"
            tailwind-border="4"
            tailwind-color="pink-500"
          />
        </div>
      </template>
      <template v-if="!isLoading && gigs.length">
          <p class="font-semibold text-md my-8 text-gray-500">Credits Used: {{ gigs.length }}</p>

          <div class="hidden mt-12 py-2 bg-gp_black-default-500 rounded-lg max-w-5xl space-x-44 uppercase text-gray-200 text-sm font-medium xl:space-x-8 xl:grid grid-cols-1 xl:grid-cols-3">
            <div class="ml-2 flex invisible space-x-3 w-52 xl:visible">
              <div class="bg-gp_pink-default-400 w-[2px] h-5 rounded-lg"/>
              <span>Gig Name</span>
            </div>
            <div class="flex invisible space-x-3 w-52 xl:visible">
              <div class="bg-gp_pink-default-400 w-[2px] h-5 rounded-lg"/>
              <span>Gig Start</span>
            </div>
            <div class="hidden space-x-3 xl:flex">
              <div class="bg-gp_pink-default-400 w-[2px] h-5 rounded-lg"/>        
              <span>Gig Status</span>
            </div>
          </div>

          <div v-for="gig in gigs" :key="gig.id" class="mt-5 max-w-7xl border border-gray-300 rounded-lg p-2 items-center xl:space-x-8 grid grid-cols-1 xl:grid-cols-3">
            <div>
              <span>{{ gig.name }}</span>
            </div>
            <div>
              <span>{{ fullDate(gig.start) }}</span>
            </div>
            <div class="mt-4 xl:mt-0 space-y-4">
              <GigTagByStatus :status="gig.status"/>
              <div class="flex items-center space-x-3">
                <EyeIcon @click="$router.push('/gigs/' + bid.gig.id)" class="w-5 h-5 text-gp_pink-default-500 cursor-pointer"/>
                <router-link :to="'/gigs/' + gig.id"  class="text-gp_pink-default-500 text-md font-extralight hidden md:block cursor-pointer">View gig</router-link>
              </div>
            </div>
          </div>
      </template>
      <template v-if="!isLoading && !gigs.length">
        <div class="p-8 mt-32 flex justify-center items-start">
          <span>No gig credits have been used!</span>
        </div>
      </template>
    </div>
  </PageDetails>
</template>

<script>
import PageDetails from "@/components/ui/wrappers/PageDetails.vue";
import { paymentsMethods } from "@/state/helpers";
import NProgress from "nprogress";
import VenueTabs from "../../components/venues/VenueTabs.vue";
import GigTagByStatus from '../../components/iconsets/GigTagByStatus.vue'
import fullDate from  '@/utils/format-date-full.js'  
import { EyeIcon } from "@vue-hero-icons/outline"

export default {
  components: {
    PageDetails,
    VenueTabs,
    GigTagByStatus,
    EyeIcon
  },
  props: ['venueId'],
  data() {
    return {
      venue_id: this.venueId,
      isLoading: false,
      gigs: [],
    };
  },
  computed: {
    venue() {
      return this.$store.getters['venue/currentVenue']
    },
  },
  methods: {  
    ...paymentsMethods,
    fullDate,
    async loadData() {
      NProgress.start();
      this.isLoading = true;
      await this.getGigListCreditUsed({ venue_id: this.venue_id }).then(response => (this.gigs = response.data));
      this.isLoading = false;
      NProgress.done();
    }
  },
  mounted() {
    this.loadData();
  }
};
</script>
