<template>

		<GigPageWrapper
			:gig-id="String($attrs.gigId)" 
			current-route="details"
			@loaded="onCalendarWrapperLoaded"
		>
    <InlineNotification v-if="!isGigCancelled || !item" Heading="Gig details" DismissId="NewGig">
        <template slot="body">
          <p v-if="!$attrs.gigId">Fill in the details below in order to create the gig.</p>
          <p v-if="$attrs.gigId">Update your gig details below, click 'Save' to apply any changes.</p>
        </template> 
    </InlineNotification> 

    <InlineNotification v-if="item && isGigCancelled">
        <template slot="body">
          <p class="text-lg text-black inline-flex"><DocumentTextIcon class="mr-2" />Gig Details (Status: CANCELLED)</p> 
          <p v-if="item.name">Event: {{ item.name }} </p>
          <p v-if="item.venue.name">Venue: {{ item.venue.name }} </p>       
          <p v-if="item.start && item.end">Date: {{ fullDate(item.start) }} - {{ getDateTime(item.end) }}</p>
        </template> 
    </InlineNotification> 

       <InlineNotification v-if="item && isGigCancelled && (item.load_in_time || item.sound_check_time || item.performance_times)" class="">
            <template slot="body">
                <p class="text-lg text-black inline-flex"><DocumentTextIcon class="mr-2" />Setup Details</p> 
                <p v-if="item.load_in_time" class="text-black mt-4">Load in times: </p>
                <p v-if="item.load_in_time" class="text-justify">{{ item.load_in_time }}</p> 
                <p v-if="item.sound_check_time" class="text-black mt-4">Sound check times: </p>
                <p v-if="item.sound_check_time" class="text-justify">{{ item.sound_check_time }}</p> 
                <p v-if="item.performance_times" class="text-black mt-4">Performance times: </p>
                <p v-if="item.performance_times" class="text-justify">{{ item.performance_times }}</p>
            </template> 
        </InlineNotification> 

        <InlineNotification v-if="item && isGigCancelled && (item.equipment_venue || item.equipment_artist)" class="">
            <template slot="body">
                <p class="text-lg text-black inline-flex"><DocumentTextIcon class="mr-2" />Equipment Details</p>
                <p v-if="item.equipment_venue" class="text-black mt-4">Equipment provided by venue:</p>
                <p v-if="item.equipment_venue" class="text-justify">{{ item.equipment_venue }}</p> 
                <p v-if="item.equipment_artist" class="text-black mt-4">Equipment provided by artist: </p>
                <p v-if="item.equipment_artist" class="text-justify">{{ item.equipment_artist }}</p> 
            </template> 
        </InlineNotification> 

        <InlineNotification v-if="item && isGigCancelled && (item.repertoire || item.dress_code || item.parking_details || item.additional_notes || item.payment_terms)" class="">
            <template slot="body">
                <p class="text-lg text-black inline-flex"><DocumentTextIcon class="mr-2" />Performance Notes</p> 
                <p v-if="item.repertoire" class="text-black mt-4">Repertoire:</p>
                <p v-if="item.repertoire" class="text-justify">{{ item.repertoire }}</p> 
                <p v-if="item.dress_code" class="text-black mt-4">Dress code:</p>
                <p v-if="item.dress_code" class="text-justify">{{ item.dress_code }}</p> 
                <p v-if="item.parking_details" class="text-black mt-4">Parking details:</p>
                <p v-if="item.parking_details" class="text-justify">{{ item.parking_details }}</p> 
                <p v-if="item.additional_notes" class="text-black mt-4">Additional notes:</p>
                <p v-if="item.additional_notes" class="text-justify">{{ item.additional_notes }}</p> 
                <p v-if="item.payment_terms" class="text-black mt-4">Payment terms:</p>
                <p v-if="item.payment_terms" class="text-justify">{{ item.payment_terms }}</p> 
            </template> 
        </InlineNotification>  


                <form v-show="this.isLoaded && (!isGigCancelled || !item)" class="form gap-3" ref="form" novalidate>



                        <h2 class="col-span-full ">Where and when</h2>

                    <div class="col-span-full sm:col-span-3 input-group">
                        <label for="event-start">
                            Start Date/Time
                        </label>
                      <div class="grid grid-cols-3 gap-4">
                        <div class="col-span-3 lg:col-span-1">
                          <input ref="start" type="date" v-model="local_start_date" class="datepick h-full" placeholder="dd/mm/yyyy" required/>
                        </div>
                        <div class="col-span-3 lg:col-span-2">
                          <div class="flex flex-row items-center">
                            <TSelect v-model="start_hour" :options="populateTime(24)">

                            </TSelect>
                            <span class="mx-2">:</span>
                            <TSelect v-model="start_minute" ref="" :options="populateTime(60)">

                            </TSelect>
                          </div>
                        </div>
                      </div>
                        <div class="input-error">
                        </div>
                           
                    </div>
 

                    
                    <div class="col-span-full sm:col-span-3 input-group">
                        <label for="event-start">
                            End Date/Time (<span v-if="endDate" v-html="endDate.toFormat('dd/MM/yyyy HH:mm')"></span>)
                        </label>
                        <div class="flex flex-row items-center">
                            <TSelect v-model="end_hour" :options="populateTime(24)">
                            
                            </TSelect>
                            <span class="mx-2">:</span>
                            <TSelect v-model="end_minute" ref="" :options="populateTime(60)">
                            
                            </TSelect>
                        </div>
                    </div>
                    <div class="input-group col-span-full sm:col-span-3">
                        <label for="venue" class="label"> 
                            Venue
                        </label>
                        <div class="mt-1">
                            <TRichSelect 
															required 
															id="venue-selection" 
															name="venue-selection" class="trichselect"                    
															:options="userVenues"
															error="A venue must be selected" 
															value-attribute="id" 
															text-attribute="name" 
															v-model="selectedVenueId"
														/>
                        </div>
                                    
                        <div class="input-error">
                        </div>
                    </div>

                    <div  class="sm:col-span-3 hidden">
                        <label for="team-member" class="block text-sm font-medium text-gray-700">
                            Venue's Primary Contact
                        </label>
                        <div class="mt-1">                            
                            <select id="team-member" name="team-member">
                                <option>Select a team member</option>
                            </select>
                        </div>
                     </div>

										<!-- 				
											v-on:input="form.name = $event;" 
											v-bind:value="form.name"  
										-->
                    <div  class="col-span-full sm:col-span-3 input-group" >
											<InputText
												required 
												id="name" 
												v-model="form.name"
												label="Event Name" 
												hint="The event name must be populated e.g. Thursdays at Corkies"
												error="The event name must be completed" 
												name="name"
                      />
                        
                     </div>
                     
                     <div v-if="item == null && gigtemplates && gigtemplates.length > 0" class="col-span-full grid gap-x-24  grid-cols-6" v-show="this.gigtemplates && this.gigtemplates.length > 0">
                        <h2 class="mt-8 col-span-full"><em>(Optional)</em> Speed up the process by using a template</h2>
 
                        <div class=" col-span-6 ">
                            <label for="enabled col-start-full block">
                                Apply an existing gig template
                            </label>
                            <div class="flex space-x-2 md:space-x-6 items-center">
                              <TRichSelect id="template-selection" v-model="gig_template_id" @change="applyTemplate" class="col-span-3 trichselect w-80" ref="templatepicker" name="template-selection"                      
                                  :options="gigtemplates"
                                  value-attribute="id" placeholder="Choose a template to apply"
                                  text-attribute="name" 
                              />
                              <XCircleIcon v-show="gig_template_id" @click="removeTemplate" class="mr-2 cursor-pointer"/>
                            </div>
                        </div> 
                     
                    </div>
										
										<!-- <div class="form gap-3 col-span-full"> -->
											<h2 class="col-span-full my-4">
												Budget and artist requirements
											</h2>
											<div  class="block col-span-full input-group" >
												<label  class="block text-sm font-medium text-gray-700">
                            Add a fee for a specific artist type:
                        </label>

                        <div class="mt-1 w-[400px]">


                            <TRichSelect
                                id="typeSelection"
                                ref="typeSelection"
                                name="type-selection"
                                class="trichselect"
                                :options="loadedTiers"
                                value-attribute="id"
                                @change="selectArtistType($event)" placeholder="Choose a type of artist"
                                text-attribute="name"
                            ></TRichSelect>
                        </div>
                          <div class="mt-1 col-span-1">

                          <!-- FEE's -->
                          <Fees
                              ref="fees"
                              :gig-tiers="fees"
                              @removeTier="removeTier"
                              :prefix="prefix"
                          ></Fees>
                        </div>
											</div>



                  <div  class="block col-span-3 input-group mt-6" >
                    <label  class="block text-sm font-medium text-gray-700">
                      Add default artist search types:
                    </label>

                    <div class="mt-1">

                      <span class="mb-2 mt-3 block">Pick a type of artist you'd like to search for</span>
                      <t-rich-select

                          :close-on-select="true" v-model="search_tiers[0]"
                          :options="tiers"
                          :name="'tiers'"
                          text-attribute="name"
                          value-attribute="id"
                          placeholder="Type"
                          class="w-full"
                          ref="tiers"
                          @change="tiersChange"
                      ></t-rich-select>

                      <SearchByTag ref="SearchByTag" class="pb-48"
                                   :tagLimitReached="tagLimitReached"
                                   :search_tiers="search_tiers"
                                   :search_genres="search_genres"
                                   :search_covers="search_covers"
                                   :search_moreInfo="search_moreInfo"
                                   :single_tier="true"
                                   :showTiers="false"
                                   :tiers="[]"
                                   :covers="covers"
                                   :genres="genres"
                                   :more-info="moreInfo"
                                   @genresChange="genresChange"
                      ></SearchByTag>
                    </div>
                  </div>

											<div
												v-if="selectedVenueMultipleBookings"
												class="block col-span-2 input-group"
											>
												<label  class="block text-sm font-medium text-gray-700">
														Mark as confirmed when:
													</label>

													<div class="mt-1 fix-input-to-match-select">
														<InputText
															type="number"
															placeholder="1"
															name="maxBookings"
															:suffix="maxBookingsSuffix"
															v-model="maxBookings"
															:disabled="false"
															min="0"
															class="fix-input-to-match-select-input"
														/>
													</div>
											</div>
										<!-- </div> -->
                    <div id="scrollmove" class="pb-12"></div>

                     <div
                class="hidden  sm:grid-cols-4 grid-flow-col col-span-full sm:gap-4 sm:items-start sm:pt-0"
              >
                <label
                  for="paused"
                  class="block text-sm font-medium text-gray-700 sm:mt-px justify-center"
                >
                  Mark this gig as fully confirmed.  This will stop any new offers coming in and show as completed.  Existing offers can still be actioned.
                </label>
                <div class="mt-1 sm:mt-0 sm:col-span-2">
                  
                  <t-toggle name="paused" id="paused" v-model="form.paused" ref="paused"></t-toggle>
                </div>
              </div>
              
              </form>

              <div v-show="this.isLoaded && isGenre && hasTagAccess && (!isGigCancelled || !item)" class="pb-16 col-span-full mt-8">
                  <strong>Custom Tags</strong>
                  <div class="flex items-center space-x-3 mt-2">
                    <div class="w-80">
                      <TRichSelect 
                        multiple
                        v-model="chosen_custom_tags"
                        ref="iconselect"  
                        class="col-span-12 lg:col-span-5 cursor-pointer"
                        :close-on-select="true" valueAttribute="id"  textAttribute="name"
                        :options="custom_tags"
                        placeholder="Add custom tags to the gig" 
                      >  
                        <template slot="option" slot-scope="{ index, isHighlighted, isSelected, className, option }">                                 
                          <div class="flex gap-4 py-2">
                            <component :is="icons[option.raw.icon]" class="ml-3"/>  {{ option.raw.name }} 
                          </div>
                        </template>

                      </TRichSelect> 
                    </div>
                    <div>
                      <button @click="openCreateTagModal()" class="flex justify-center items-center bg-gp_pink-default-500 hover:bg-gp_pink-default-400 text-white text-sm font-bold py-1 px-4 rounded-3xl">
                        <component :is="icons['PlusIcon']" class="w-5 h-5 mr-1"/>
                        <span class="hidden md:block">New Tag</span>
                      </button>
                    </div>
                  </div>
              </div>

                
                <div v-if="this.isLoaded" class="action-bar">       
										<template v-if="item && isGigCancelled">
											<span class="text-xs uppercase justify-center h-full text-gray-600">This gig has been cancelled</span>
										</template>
										<template v-else-if="this.item && this.item.published && this.item.bookings.length == 0">
											<span class="text-xs uppercase justify-center h-full text-gp_pink-default-500">GigPig is looking for artists</span>
										</template>
                    
										<template v-if="showCancelButton">
											<GPButton color="gray" size="lg" @click.native="onCancelBooking">Cancel / re-list gig</GPButton>   
										</template>

                    <template v-if="item && hasManageGigsPermission">
                      <GPButton v-if="showDeleteButton"
                                    @click.native="deleteItem()"
                                    :disabled="btnDisabled"
                                    color="indigo"
                                    size="lg"
                                    ><TrashIcon class="mr-2"/>Delete gig</GPButton>
                    </template>	


                      <GPButton v-if="!this.cancelledCheck"
                        @click.native="save($attrs.gigId, false)" 
                        :disabled="btnDisabled"
                        :loading="btnDisabled"
                        size="lg"
                      ><PlusCircleIcon v-if="!$attrs.gigId" class="mr-2"/><SaveIcon v-else class="mr-2"/>{{ this.CreateOrUpdate }}</GPButton> 			       
                 
                </div>
        <NoDataMsgWrapper v-if="!this.isLoaded">Loading gig details. Please wait.</NoDataMsgWrapper>

        <GigTemplateCopy ref="TemplateCopyModal" v-on:templateSelection="applyCopyTemplate($event)" />
        <NotificationSmall ref="notification" />
        <CreateCustomGigTag ref="createGigTag" v-on:addedTag="newCreatedTag($event)"/> 

				<!-- Modals -->
				<template v-if="item">
					<CancelBooking	
						identity="cancelBookingModal"
						:is-open="cancelBookingModalIsOpen" 
						:gig-id="item.id"
						:gig-name="item.name"
						:gig-start="item.start" 
            :gig="item"
            :booking="selectedBooking"
						@closed="onModalClosed"
						@deleted="onCancellationComplete"
					></CancelBooking>
				</template>

    <DeleteGigConfirmation ref="deletegig" />
    <TopUpOptions ref="topupmodal" />
    <StripePAYGToRyftPAYG ref="stripetoryft" v-on:createGig="switchVenuePaymentProcessAndCreateGig()"/>
		</GigPageWrapper>
</template>

<script>  
import Pristine from '../../utils/pristine.js'
import GigTemplateCopy from '../../components/modal/GigTemplateCopy.vue' 
import TopUpOptions from '../../components/modal/venues/subscriptions/TopUpOptions.vue'
import StripePAYGToRyftPAYG from '../../components/modal/venues/subscriptions/StripePAYGToRyftPAYG.vue'
import Fees from '../../components/gigs/Fees.vue'
import '../../utils/pristine.js'
import GigPageWrapper from '@/components/gigs/GigPageWrapper.vue'; 
import NotificationSmall from '../../components/notifications/small.vue';
import InputText from '../../components/form/InputText.vue';
import { TRichSelect, TSelect } from 'vue-tailwind/dist/components';
import { apiComputed, apiMethods, paymentsMethods } from '@/state/helpers'; 
import InlineNotification from '../../components/notifications/inline.vue';
import formatDate from '../../utils/format-date.js';
import normalizeDate from '../../utils/normalizeDate.js';
import GPButton from '../../components/ui/buttons/Button.vue'
import CancelBooking from '../../components/modal/gigs/CancelBooking.vue'; 
import { TrashIcon, PlusCircleIcon, SaveIcon, DocumentTextIcon, XCircleIcon } from "@vue-hero-icons/outline";
import DeleteGigConfirmation from '@/components/modal/DeleteGigConfirmation.vue';
import NProgress from 'nprogress';
import NoDataMsgWrapper from '@/components/ui/messages/NoData.vue';
import fullDate from  '@/utils/format-date-full.js'

import CreateCustomGigTag from '../../components/modal/venues/CreateCustomGigTag.vue';
import {mapState} from "vuex";
import SearchByTag from '../../components/gigs/SearchByTag.vue';
const { DateTime } = require("luxon");

import {
  TToggle 
} from 'vue-tailwind/dist/components'
import store from "@/state/store";
import client from '@/utils/client.js'
import  * as icons from "@vue-hero-icons/outline"
import {TAG_TYPES} from "@/state/models/tagType";
import { mapActions } from 'vuex';

export default {
    name: "GigDetails",
    components:{
				GigPageWrapper, 
        TRichSelect,
        DeleteGigConfirmation,
        NotificationSmall,
        InputText, 
        InlineNotification,
        TToggle, Fees, GigTemplateCopy,TSelect,
				GPButton,
				CancelBooking,
        NoDataMsgWrapper,
        TrashIcon, PlusCircleIcon, SaveIcon, DocumentTextIcon, XCircleIcon,
        CreateCustomGigTag,
      SearchByTag,
      TopUpOptions,
      StripePAYGToRyftPAYG
    }, 
    data () {
 
       return {   
            btn: false,
						isLoading:false,
            isLoaded: false,
            cancelledCheck: null,
            local_start: null,
            local_start_date: null,
            item: null,
            start_hour: '18',
            start_minute: 0,
            end_hour: '23',
            end_minute: '45',
            fees: [],
						loadedTiers:this.$store.getters['api/tiers'],
            pristine: null,            
            selectedTiers: [],
            copyTemplate: null,
            gig_template_id: null, 
            is_amended_end: false,
            pristineConfig: {
                classTo: 'input-group',
                errorClass: 'has-error',
                successClass: 'has-success',
                errorTextParent: 'input-error',
                errorTextTag: 'span',
                errorTextClass: 'text-help'
            },
						cancelBookingModalIsOpen:false,
						selectedBooking:null,
						selectedVenueId:null,
						selectedVenueMultipleBookings:false,
						maxBookings:'1',
            userVenues: null,
            prefix: '£',
            custom_tags: [],
            chosen_custom_tags: [],
            icons: icons,
            search_tiers: [],
            search_genres: [],
            search_covers: [],
            search_moreInfo: [],
            covers: [],
            genres: [],
            moreInfo: [],
            tagsLoaded: false,
            searchByTagLoaded: false,
         loadSearchByTag: false,
         addingTagsFromTemplate: false,
         selectionLimit: 5,
         tagLimitReached: false,
       }
    },
    mounted()
    {
      if(store.getters['user/hasManageGigsPermission']){
        // do route stuff
        store
            .dispatch('api/getGigTemplates').then()
      }
				this.onCalendarWrapperLoaded();
				this.loadGig();
    },
  computed: {  
    ...apiComputed, 
    ...mapActions('account', ['updateAccInfo', 'updateVenueInfo']),
    ...mapState({
			userRoles: (state) => state.user.rolesPermissionsSlugs,
		}),
    hasTagAccess()
    {
        if (this.userRoles && this.userRoles.length > 0) {
            return this.userRoles[0] == 'full-access';
        } else {
            return null; 
        }
    },
    userData(){
			return this.$store.getters['user/userData']
		},
		isGenre() {
      return this.userData && this.userData.email.includes('genremusic')
		},
    hasManageGigsPermission(){
				return this.$store.getters['user/hasManageGigsPermission']
		},
    showDeleteButton()
    {
        return this.item.status === 'UNPUBLISHED' || this.item.status === 'CANCELLED' || this.item.status === 'PENDING' || this.item.status === 'NEEDS_RESPONSE';
    },
    btnDisabled(){
        return this.btn;
    },
    endDate: function()
    {
        if (this.local_start == null)
        return;
        



        let start = DateTime.fromFormat(this.local_start, "yyyy/MM/dd HH:mm");
        let end = DateTime.fromFormat(this.local_start, "yyyy/MM/dd HH:mm");


        end = end.set({hour: this.end_hour}).set({minute: this.end_minute});



        if (new Date(start.toUTC()) > new Date(end.toUTC()))
        {
            // eslint-disable-next-line vue/no-side-effects-in-computed-properties
            this.is_amended_end = true;
            end = end.plus({days: 1});
        }
        else
        {
             // eslint-disable-next-line vue/no-side-effects-in-computed-properties
             this.is_amended_end = false;
        }



        // let $endDate = end.toUTC();

        return end;
    },
    nextDayMessage: function(){
        return this.formatDate(this.form.end, "dd/MM/yyyy");
    },
    selected_venue: function()
    {
        return this.item ? this.item.venue_id : ''
    },
    form: function() {
        // eslint-disable-next-line vue/no-side-effects-in-computed-properties
 
        let end = DateTime.now().set({hours: 23, minutes: 45}).toFormat('yyyy/MM/dd HH:mm');
 
        let $existingitem = this.item != null;

        if ($existingitem){

            let payment_type = this.item.venue.payg_charged_and_billed_monthly ? 'payg_charged_and_billed_monthly' : 'payg';

            let selected_venue = {
              credits_remaining: this.item.venue.active_tokens.length,
              id: this.item.venue.id,
              name: this.item.venue.name,
              expiry_year: this.item.venue.expiry_year,
              expiry_month: this.item.venue.expiry_month,
              payment_process: this.item.venue.payment_process,
              currency_id: this.item.venue.currency_id,
              payment_type: payment_type,
              has_subscription: this.item.venue.active_subscription && this.item.venue.active_subscription.subscription_plan && this.item.venue.active_subscription.subscription_plan.id !== 1 ? true : false
            }
 
            this.$store.dispatch('account/updateVenueInfo', selected_venue);
        }


 
        return{
            id: $existingitem ? this.item.id : null,
            name: $existingitem? this.item.name : '',
            start: $existingitem? this.item.start : this.local_start,
            end: $existingitem? normalizeDate(this.item.end) : end,
            venue: $existingitem? this.item.venue : '',
            venue_id: $existingitem && this.item.venue ? this.item.venue.id : '',
            paused: $existingitem? this.item.paused : false,
            fees: $existingitem ? this.item.fees : null,
            search_tiers: $existingitem ? this.item.gig_tiers.map((tier) => {return tier.tier}) : null,
						//this.$refs.SearchByTag.search_tiers = this.item.gig_template_search_tiers.map((tag) => {return tag.tier_id});
        }
        },
        CreateOrUpdate: function()
        {
            return this.item ? 'Save & Next' : 'Create Gig'
        },
			showCancelButton(){
				return !this.item || !this.item.published ? false : !this.item.cancelled;
			},
			isGigCancelled(){
        return this.$store.getters['gig/isCurrentGigCancelled']
			},
			disableMaxBookings(){
				return this.item ? true : false;
			},
			maxBookingsSuffix(){
				return `artist${Number(this.maxBookings) === 1 ? '' : "s"} booked`
			},
      payloadFees() {
        return this.fees.map(t => {
          return {
            tier_id: t.tier.id,
            name: t.tier.name,
            requested_fee: t.requested_fee,
            tier: t.tier
          }
        })
      },
    }, 
    watch:{
			selectedVenueId(updatedVenueId){

        if (!updatedVenueId) {
          return;
        }
        
        if (this.userVenues) {
          this.prefix = this.userVenues.find(v => {
            return v.id === updatedVenueId
          }).currency.symbol
        }
				this.checkVenue(updatedVenueId)
			},
      local_start_date(val) {
        this.local_start = DateTime.fromFormat(val, "yyyy-MM-dd")
            .set({hour: this.start_hour, minute: this.start_minute})
            .toFormat('yyyy/MM/dd HH:mm');
      },
      start_hour: function (val) {
        let start_hour = DateTime.fromFormat(this.local_start, "yyyy/MM/dd HH:mm").set({hours: val}).toFormat('yyyy/MM/dd HH:mm');
        this.local_start = start_hour;
      },
      start_minute: function (val) {
        let start_min = DateTime.fromFormat(this.local_start, "yyyy/MM/dd HH:mm").set({minutes: val}).toFormat('yyyy/MM/dd HH:mm');
        this.local_start = start_min;
      },
		},
    methods:{
      ...paymentsMethods,
      tiersChange(e) {

        if (this.item) {
          if (!this.loadSearchByTag) {
            this.search_genres = this.item.artist_tags.filter(tag => tag.tag_type === TAG_TYPES.GENRE).map((tag) => {return tag.id });
            this.search_covers = this.item.artist_tags.filter(tag => tag.tag_type === TAG_TYPES.COVERS).map((tag) => {return tag.id });
            this.search_moreInfo = this.item.artist_tags.filter(tag => tag.tag_type === TAG_TYPES.MORE_INFO).map((tag) => {return tag.id });
            this.loadSearchByTag = true;
          }
        } else if (this.addingTagsFromTemplate) {
            let template = this.gigtemplates.find(f => f.id == this.gig_template_id);
            this.search_genres = template.artist_tags.filter(tag => tag.tag_type === TAG_TYPES.GENRE).map((tag) => {return tag.id });
            this.search_covers = template.artist_tags.filter(tag => tag.tag_type === TAG_TYPES.COVERS).map((tag) => {return tag.id });
            this.search_moreInfo = template.artist_tags.filter(tag => tag.tag_type === TAG_TYPES.MORE_INFO).map((tag) => {return tag.id });

            this.addingTagsFromTemplate = false;
        }

        let tiers = [e];
        let genres = [];
        let covers = [];
        let moreInfo = [];
        let toSplice = [];

        // get all the genres associated to the picked tiers
        tiers.forEach((t) => {

          this.loadedTiers.filter((f) => {
            if (t === f.id) {
              f.genres.forEach((x) => {
                genres.push(x)
              })
              f.covers.forEach((x) => {
                covers.push(x)
              })
              f.more_info.forEach((x) => {
                moreInfo.push(x)
              })
            }
          })
        })

        // remove the duplicate objs from the array
        this.genres = genres.filter((value, index) => {
          const _value = JSON.stringify(value);
          return index === genres.findIndex(obj => {
            return JSON.stringify(obj) === _value;
          });
        })

        this.covers = covers.filter((value, index) => {
          const _value = JSON.stringify(value);
          return index === covers.findIndex(obj => {
            return JSON.stringify(obj) === _value;
          });
        })

        this.moreInfo = moreInfo.filter((value, index) => {
          const _value = JSON.stringify(value);
          return index === moreInfo.findIndex(obj => {
            return JSON.stringify(obj) === _value;
          });
        })

        // splice genres that dont belong to the tier
        let genreChoices = Object.values(JSON.parse(JSON.stringify(this.search_genres)));

        genreChoices.forEach((c) => {
          let target = genres.filter((f) => { return f.id === c ? f : null });

          if (target.length === 0) {
            toSplice.push(c);
          }
        })

        toSplice.forEach((c) => {
          let index = genreChoices.indexOf(c);
              if (index !== -1) {
                genreChoices.splice(index, 1);
              }
        })

        this.search_genres = Object.assign([], genreChoices);



        let coversChoices = Object.values(JSON.parse(JSON.stringify(this.search_covers)));

        coversChoices.forEach((c) => {
          let target = covers.filter((f) => { return f.id === c ? f : null });

          if (target.length === 0) {
            toSplice.push(c);
          }
        })

        toSplice.forEach((c) => {
          let index = coversChoices.indexOf(c);
              if (index !== -1) {
                coversChoices.splice(index, 1);
              }
        })

        this.search_covers = Object.assign([], coversChoices);



        let moreInfoChoices = Object.values(JSON.parse(JSON.stringify(this.search_moreInfo)));

        moreInfoChoices.forEach((c) => {
          let target = moreInfo.filter((f) => { return f.id === c ? f : null });

          if (target.length === 0) {
            toSplice.push(c);
          }
        })

        toSplice.forEach((c) => {
          let index = moreInfoChoices.indexOf(c);
              if (index !== -1) {
                moreInfoChoices.splice(index, 1);
              }
        })

        this.search_moreInfo = Object.assign([], moreInfoChoices);

      },
      genresChange(selectedGenres) {
        if (selectedGenres.length > this.selectionLimit) {
          this.search_genres = selectedGenres.slice(0, this.selectionLimit);
        }

        this.tagLimitReached = selectedGenres.length === this.selectionLimit
      },
      fullDate,
      openCreateTagModal() {
        this.$refs.createGigTag.modalOpen = true;
      },
      newCreatedTag(event) {
        this.custom_tags.push(event)
      },
      removeTemplate()
      {
        this.gig_template_id = null;
        this.fees = [];
        this.search_tiers = [];
        this.$refs.TemplateCopyModal.chosenTemplate = null;
        this.copyTemplate = null;
      },
			onCalendarWrapperLoaded(){
				if(!this.pristine && this.$refs.form){
					this.pristine = new Pristine(this.$refs.form, this.pristineConfig);
				}
			},
      getDateTime($date){
        let normalised = normalizeDate($date);
        let datetime = DateTime.fromFormat(normalised, 'yyyy/MM/dd HH:mm');
        return datetime.toFormat('HH:mm');
      },
        async loadGig(){

          await client.get('custom-tags').then(response => (this.custom_tags = response.data.data))
          .catch((error) => console.log(error));

          this.$store.dispatch('venue/getVenuesCurrencyList')
              .then((response) => {
                this.userVenues = response;
              })

            if (this.$attrs.gigId)
            {
              NProgress.start();

							Promise.all([
								this.$store.dispatch('gig/getGigDetails', this.$attrs.gigId)
								.then((response) => {


                  if (response.venue.payment_process == "ryft") {

                    let payment_type = response.venue.payg_charged_and_billed_monthly ? 'payg_charged_and_billed_monthly' : 'payg';

                    let selected_venue = {
                      credits_remaining: response.venue.active_tokens.length,
                      id: response.venue.id,
                      name: response.venue.name,
                      expiry_year: response.venue.expiry_year,
                      expiry_month: response.venue.expiry_month,
                      payment_process: response.venue.payment_process,
                      currency_id: response.venue.currency_id,
                      payment_type: payment_type,
                      has_subscription: response.venue.active_subscription && response.venue.active_subscription.subscription_plan && response.venue.active_subscription.subscription_plan.id !== 1 ? true : false
                    }

                    this.$store.dispatch('account/updateVenueInfo', selected_venue);
                  }

									this.item = JSON.parse(JSON.stringify(response));
                  this.chosen_custom_tags = this.item.custom_tags.map(obj => obj.id);
                  this.cancelledCheck = response.cancelled;
								})
                .catch(() => {
                  this.$notify({ title: 'An error occurred. If this message persists, please contact support@gigpig.uk', duration: 10000});
                  this.$router.push('/calendar')
                })
							])
							.finally(() => {
								if (this.form.venue_id != null)
									{
										this.selectedVenueId = this.form.venue_id;

									}

									if (this.item)
									{
                      this.search_tiers = this.item.gig_search_tiers.map(t => t.id);

											this.local_start = normalizeDate(this.item.start);
                      this.local_start_date = DateTime.fromFormat(this.local_start, "yyyy/MM/dd HH:mm").toFormat('yyyy-MM-dd');
                      this.start_hour = DateTime.fromFormat(this.local_start, "yyyy/MM/dd HH:mm").toFormat('HH');
                      this.start_minute = DateTime.fromFormat(this.local_start, "yyyy/MM/dd HH:mm").toFormat('mm');
											this.end_hour = DateTime.fromISO(this.item.end).toUTC().toFormat('HH');
											this.end_minute = DateTime.fromISO(this.item.end).toUTC().toFormat('mm');

											this.fees = this.item.gig_tiers;
                      this.isLoaded = true;
                      NProgress.done();
									}
							});
						} else {

              this.$store.dispatch('venue/getVenuesCurrencyList')
              .then((response) => {              
                if (response.length == 1){ 
                  this.selectedVenueId = response[0].id;
                }
              }) 

              this.isLoaded = true;
            }

            this.local_start_date = DateTime.now().toFormat('yyyy-MM-dd');
            this.local_start = DateTime.now().set({hours: 18, minutes: 0}).toFormat('yyyy/MM/dd HH:mm');
            if (this.$route.query.start)
            { 
                // eslint-disable-next-line vue/no-side-effects-in-computed-properties
                const start_iso_date = DateTime.fromISO(this.$route.query.start);
                this.local_start_date = start_iso_date.toFormat('yyyy-MM-dd');
                this.start_hour = start_iso_date.toFormat('HH');
                this.start_minute = start_iso_date.toFormat('mm');
                let end = DateTime.fromISO(this.$route.query.end);

                // eslint-disable-next-line vue/no-side-effects-in-computed-properties
                this.end_hour = end.toFormat("HH");
                // eslint-disable-next-line vue/no-side-effects-in-computed-properties
                this.end_minute = end.toFormat("mm");
            }

        },
        normalizeDate,    
        populateTime(limit){
            let hours = [];

            for (let hour = 0; hour < limit; hour++){
                
                let hourOut = hour;
                if (hourOut < 10)
                {
                    hourOut = '0' + hour
                }
                else{
                    hourOut = hour.toString();
                }

                hours.push(hourOut)
            }

            return hours;
        },
        formatDate,
        applyCopyTemplate(copy){

            let template = this.gigtemplates.find(f => f.id == this.gig_template_id);

            this.copyTemplate = copy;

            if (copy.use_search_preferences) {

              this.addingTagsFromTemplate = true

              this.search_tiers = template.gig_template_search_tiers.map(tag => tag.tier_id);

              this.tiersChange(this.search_tiers[0]);
            }

            if (copy.use_fees)
            {
                this.fees = template.gig_template_tiers.map(t => {

                let tier = this.loadedTiers.find(lt => lt.id === t.tier_id);

                    return {
                        tier: tier,
                        requested_fee: t.requested_fee,
                        tier_id: t.tier_id,
                        id: tier.id
                    }

                });

                setTimeout(function(){
                    document.getElementById("scrollmove").scrollIntoView({behavior: 'smooth'});
                }, 500)
            }

        },
        applyTemplate(selectedTemplate){
            let template = this.gigtemplates.find(f => f.id == selectedTemplate);

            this.$refs.TemplateCopyModal.updateTemplate(template);
            this.$refs.TemplateCopyModal.toggleVisibility();
        },
        getFees($fees)
        {
            this.fees = $fees;
        },
        selectArtistType($ev){
					const selectedTier = this.loadedTiers.find(t => t.id === $ev)
					if(!selectedTier){
						return null
					}

					if (!this.fees.filter(f => f.tier.id === selectedTier.id).length) {
                        this.fees.push({
                            tier: selectedTier,
                            requested_fee: null,
                            tier_id: selectedTier.id,
                            id: selectedTier.id
                        })
					}

					this.selectedTiers = this.fees.map(t => t.tier);
					// this.$refs.fees.setTiers(this.fees);
					
					setTimeout(function(){
							document.getElementById("scrollmove").scrollIntoView({behavior: 'smooth'});
					}, 500)
        },
        ...apiMethods,
        async save($gigID, published = false){
                                
            this.btn = true;

            var valid = this.pristine.validate(); 

            if (!this.selectedVenueId)
            {
                this.$refs.notification.createNotification("A venue is required", true);
                valid = false;
                this.btn = false;
                return;
            }

            if (!this.form.name)
            {
                this.$refs.notification.createNotification("An event name is required", true);
                valid = false;
                this.btn = false;
                return;
            }

            if (!this.form.name)
            {
                this.$refs.notification.createNotification("An event name is required", true);
                valid = false;
                this.btn = false;
                return;
            }

            if (this.fees.length === 0)
            {
                this.$refs.notification.createNotification("You must select at least one type of artist", true);
                valid = false;
                this.btn = false;
                return;
            }

            if (this.fees.filter(f => f.requested_fee == 0 || f.requested_fee == null).length > 0)
            {                
                this.$refs.notification.createNotification("Your artist fees must be more than £0.00", true);
                valid = false;
                this.btn = false;
                return;
            }

            if (this.fees == false || this.fees.length == 0)
            {
                this.$refs.notification.createNotification("You must include at least one type of artist", true);
                valid = false;
                this.btn = false;
                return;
            }

            if (this.local_start === 'Invalid DateTime')
            {
                this.$refs.notification.createNotification("You must provide a start date/time", true);
                valid = false;
                this.btn = false;
                return;
            }


            // check for available ryft tokens
            let venueTarget = this.userVenues.find(f => f.id === this.selectedVenueId);

            if (venueTarget.payment_process == 'ryft' && !$gigID) {
              await this.getVenueActiveTokens({ venue_id: this.selectedVenueId })
                .then((response) => {
                  if (response.data.length === 0) {
                    if (!venueTarget.payg_charged_and_billed_monthly) {
                      this.$refs.notification.createNotification("Insufficient credits. Please credit your venue to proceed.", true);
                      this.$refs.topupmodal.openWithVenueInfo({ id: venueTarget.id });
                      valid = false;
                      this.btn = false;
                    }
                  } 
                })
            }

            if (venueTarget.payment_process == 'stripe' && !$gigID && (venueTarget.active_subscription.product.id == 4 || venueTarget.active_subscription.product.id == 5)) {
              this.$refs.notification.createNotification("Please update your venues payment card information.", true);
              this.$refs.stripetoryft.addVenueInfo({ id: venueTarget.id , name: venueTarget.name, currency_id: venueTarget.currency.id });
              valid = false;
              this.btn = false;
            }

            if (valid)
            {

                let fallbackPublished = this.item ? this.item.published : published;

              let genres = [];

              if (this.search_genres != undefined)
              {
                genres = this.search_genres;
              }

              let covers = [];

              if (this.search_covers != undefined)
              {
                covers = this.search_covers;
              }

              let moreInfo = [];

              if (this.search_moreInfo != undefined)
              {
                moreInfo = this.search_moreInfo;
              }

              let tags = [...genres, ...covers, ...moreInfo];


                let payload = { 
                    id: this.form.id, 
                    venue_id:  this.selectedVenueId,
                    published: published ? published : fallbackPublished, // don't change publish value on save
                    name: this.form.name,
                    start: DateTime.fromFormat(this.local_start, 'yyyy/MM/dd HH:mm'),
                    end:  this.endDate, 
                    paused: this.form.paused,
                    fees: this.payloadFees,
                    custom_tags: this.chosen_custom_tags,
                    tags: tags,
                    search_tiers: this.search_tiers
                }
								if(this.selectedVenueMultipleBookings){
									payload.max_bookings = Number(this.maxBookings);
								}

           const self = this; 
            if (!$gigID)
            {

                if (this.copyTemplate != null)
                {
                    payload.copyTemplate = this.copyTemplate;
                    payload.gig_template_id = this.gig_template_id;
                }

                let response = null;
                this.createGig(payload).then((resp) => {
                    response = resp;


                    self.$router.push({ path: `/gigs/${response.id}/instructions`});

                        if (this.copyTemplate != null)
                        {
                            this.$notify("Gig created and template applied");
                            this.btn = false;
                        }
                        else
                        {
                            this.$notify("Gig created");
                            this.btn = false;
                        }
                    

                }).finally(() => {

 
                }).catch(($ex) => {
                    
                        this.$refs.notification.createNotification("We could not save this gig - " + $ex.response.data.message);
                            this.btn = false;
                            this.loading = false;
                            console.log("error", $ex.response.data.message);
                });

            }
            else
            {
              payload = {
                    id: this.item.id,
                    published: this.item.published,
                    name: this.form.name,
                    start: DateTime.fromFormat(this.local_start, 'yyyy/MM/dd HH:mm'),
                    end:  this.endDate,
                    paused: this.form.paused,
                    venue_id:  this.selectedVenueId,
                    custom_tags: this.chosen_custom_tags,
                    tags: tags,
                    fees: this.payloadFees,
                    search_tiers: this.search_tiers
              }

              if(this.selectedVenueMultipleBookings){
									payload.max_bookings = Number(this.maxBookings);
              }

              this.updateGig(payload)
                  .then(response => {
                      self.$router.push({ path: `/gigs/${response.id}/instructions` });
                      this.$notify("Gig updated");
                      this.btn = false;
                  }).catch(($ex) => {
                    this.$refs.notification.createNotification("We could not save this gig - " + $ex.response.data.message, true);
                    this.btn = false;
                    this.loading = false;
              });
						}

					}


        },
        switchVenuePaymentProcessAndCreateGig() {
          let index = this.userVenues.findIndex(f => f.id === this.selectedVenueId);
          this.userVenues[index].payment_process = 'ryft';
          this.save(this.$attrs.gigId, false)
        },
				//
				// open modal
				onCancelBooking(){
					// get selectedBooking
					if(!this.item || !this.item.published){
						return null
					}
					this.selectedBooking = null;
					setTimeout(() => {
						// trigger an update with the selectedBooking in modal
						this.selectedBooking = this.item.bookings[0] || null;
						this.cancelBookingModalIsOpen = true;
					},50)
					
				},
				// close modals 
				onModalClosed(){
					this.cancelBookingModalIsOpen = false;
				},
				// CRUD methods
				onCancellationComplete(payload) {
					if (payload.shouldRedirect) {
						// Use the gigId from the payload
						this.$router.push(`/gigs/${payload.gigId}/offers`);
					}
				},
				checkVenue(venueId){
        if (this.userVenues) {
          const selectedVenue = this.userVenues.filter(v => v.id === venueId)[0]
          this.selectedVenueMultipleBookings = selectedVenue.feature_multiple_bookings || false;
        }

					// Set the maxBookings if gig data is present, if not then use default
					if(this.item && this.item.max_bookings){
						this.maxBookings = String(this.item.max_bookings);
					}
				},
        deleteItem(){ 
          this.$refs.deletegig.show(this.item);
        },
      removeTier(tierId) {
        const indexToRemove = this.fees.map(gigTier => {
          return gigTier.tier.id
        }).findIndex((id) => id === tierId);
        if (indexToRemove !== -1) {
          this.fees.splice(indexToRemove, 1)
        }
      }
    },
}
</script>
