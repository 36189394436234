<template>
    <div v-show="modal_open && pending_subscriptions.length" transition="fadein" class="fixed z-10 inset-0 overflow-y-auto duration-300 ease-in transition-all modal" ref="modal" aria-labelledby="modal-title" role="dialog" aria-modal="true">
        <div class="flex items-center justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
            <div class="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" aria-hidden="true">
            </div>
            <span class="hidden sm:inline-block sm:align-middle sm:h-screen" aria-hidden="true">&#8203;</span>
            <transition appear enter-active-class="animate__animated animate__fadeInUp" leave-active-class="animate__animated animate__fadeOutDown">        
                <div class="inline-block align-bottom bg-gp_black-default-500 border border-gp_pink-default-400 rounded-3xl px-4 pt-5 pb-4 text-left shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-lg sm:w-full sm:p-6">
                    <div class="flex justify-end mb-6 text-gp_pink-default-500">
                        <XIcon @click="toggleModal" class="hover:text-gp_pink-default-400 cursor-pointer"/>
                    </div>
                    <div class="grid grid-cols-1">
                        <div>
                            <h3 class="block font-bold text-gp_pink-default-500 uppercase" id="modal-title">Pending Subscriptions</h3>
                            <div class="mt-3">
                                <p class="text-sm mt-2 text-gray-100 font-medium text-justify">
                                    The current list of venues have pending subscriptions awaiting your approval. Clicking the 'Activate subscription' button next to the venue will begin the subscription and fund the venue with credits. 
                                </p> 
                            </div>

                            <div v-for="(venue) in pending_subscriptions" :key="venue.id">
                                <hr class="my-6">
                                <p class="uppercase text-gp_pink-default-500 font-bold">{{ venue.name }}</p>
                                <div class="w-full py-3">
                                    <GPButton @click.native="activateSubscription(venue)"
                                                    :disabled="isSubmitting"
                                                    :loading="isSubmitting"
                                                    color="pink"
                                                    class="w-full justify-between"
                                    >
                                        <span class="font-semibold">Activate subscription</span>
                                        <ArrowNarrowRightIcon stroke="white" class="w-6 h-6"/>
                                    </GPButton> 
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </transition>        
        </div>
        <SaveCard ref="savecardmodal" @clearPayments="activateSubscriptionConfirmation()" />
    </div>
</template>

<script>

import { paymentsMethods, apiMethods } from "@/state/helpers";
import GPButton from '../../../../components/ui/buttons/Button.vue';
import { ArrowNarrowRightIcon, XIcon } from "@vue-hero-icons/outline"
import SaveCard from '../../../../components/modal/venues/subscriptions/SaveCard.vue'
import NProgress from 'nprogress';

export default
{
    name: "PendingSubscriptionsRyft", 
    data() {
        return {
            pending_subscriptions: [],
            modal_open: false,
            isSubmitting: false,
            selectedVenue: [],
        }
    },
    async mounted() {

        await this.getRyftVenuePendingSubscriptions()
            .then((response) => {
                this.pending_subscriptions = response.data;

                if (response.data.length) {
                    this.modal_open = true;
                }
            })
    },
    components: {
        GPButton,
        ArrowNarrowRightIcon,
        SaveCard,
        XIcon
    },
    methods: {
        ...paymentsMethods, 
        ...apiMethods,
        toggleModal() {
            this.modal_open = false;
        },
        activateSubscription(venue) {

            this.selectedVenue = venue;
            this.isSubmitting = true;

            this.getVenuePaymentCards({ venue_id: this.selectedVenue.id })
                .then((response) => {
                    if (response.length) {
                        this.activateSubscriptionConfirmation();
                    } else {
                        this.$refs.savecardmodal.addVenueInfo({ id: this.selectedVenue.id , name: this.selectedVenue.name , currency_id: this.selectedVenue.currency_id , close_modal: true });
                    }
                })
        },
        async activateSubscriptionConfirmation(){

            this.$notify("Attempting to activate subscription.");
            NProgress.start();

            await this.activateRyftVenuePendingSubscription({ venue_id: this.selectedVenue.id })
                .then((response) => {

                    if (response.response.status == 500) {
                        this.$notify({text: response.response.data.message + ": Please try again or contact support for further assistance.", duration: 5000});
                    } else {
                        this.$notify("Subscription activated.");
                        let index = this.pending_subscriptions.findIndex((f) => f.id === this.selectedVenue.id);
                        this.pending_subscriptions.splice(index, 1);
                    }

                    this.isSubmitting = false;
                    NProgress.done();
                })
        }
    }
}

</script>