// src/store/modules/payments.js

// import client from "../../utils/client"
import axios from "@/utils/client";

const state = { payments: null };

const mutations = {};

export const actions = {
    createMerchantPaymentSession({ commit }, params) {

        console.log('createMerchantPaymentSession commit', commit);

        return axios
            .put(`/payments/createMerchantPaymentSession`, params)
            .then(response => {
                console.log('createMerchantPaymentSession response', response);
                return response.data
            })
            .catch(err => {
                console.log(err)
            });
    },
    createMerchantCustomer({ commit }, params) {

        console.log('createCustomer commit', commit);

        return axios
            .put(`/payments/createCustomer`, params)
            .then(response => {
                return response.data
            })
            .catch(err => {
                console.log(err)
            });
    },
    createPaymentCard({ commit }, params) {
        console.log('createPaymentCard commit', commit);
        return axios
            .post(`/payments/createPaymentCard`, params)
            .then(response => {
                return response.data
            }) // no catch - let the error be handled by the caller
    },
    createPaymentCardFromOnboard({ commit }, params) {

        console.log('createPaymentCardFromOnboard commit', commit);

        return axios
            .post(`/payments/createPaymentCardFromOnboard`, params)
            .then(response => {
                return response.data
            })
            .catch(err => {
                console.log(err)
            });
    },
    createMerchantPaymentSessionFromOnboard({ commit }, params) {

        console.log('createMerchantPaymentSessionFromOnboard commit', commit);

        return axios
            .put(`/payments/createMerchantPaymentSessionFromOnboard`, params)
            .then(response => {
                console.log('createMerchantPaymentSessionFromOnboard response', response);
                return response.data
            })
            .catch(err => {
                console.log(err)
            });
    },
    updatePaymentCard({ commit }, params, cardId) {
        console.log('updatePaymentCard commit', commit);
        return axios
            .post(`/payments/updatePaymentCard/${cardId}`, params)
            .then(response => {
                return response.data
            })
            .catch(err => {
                console.log(err)
            });
    },
    addPayg({ commit }, params) {
        console.log('makePAYGPayment commit', commit);
        return axios
            .post(`/payments/venue/${params.venue_id}/payg`, params)
            .then(response => {
                return response.data
            })
            .catch(err => {
                console.log(err)
            });
    },
    addPaygMonthly({ commit }, params) {
        console.log('addPaygMonthly commit', commit);
        return axios
            .post(`/payments/venue/${params.venue_id}/paygMonthly`, params)
            .then(response => {
                return response.data
            })
            .catch(err => {
                console.log(err)
            });
    },
    addSinglePaygPaymentForGig({ commit }, params){
        console.log('addSinglePaygPaymentForGig commit', commit);
        return axios
            .post(`/payments/gig/${params.gig_id}/singlePayment`, params)
            .then(response => {
                return response.data
            })
            .catch(err => {
                console.log(err)
            });
    },
    addSubscription({ commit }, params) {
        console.log('addSubscription commit', commit);
        return axios
            .post(`/payments/venue/${params.venue_id}/subscriptions/${params.subscription_id}`, params)
            .then(response => {
                return response.data
            })
            .catch(err => {
                console.log(err)
            });
    },
    getVenueSubscriptionsInvoices({ commit }, params) {
        console.log('getVenueSubscriptions commit', commit);
        return axios
            .get(`/payments/venues/${params.venue_id}/payments`)
            .then(response => {
                return response.data
            })
            .catch(err => {
                console.log(err)
            });
    },
    getSubscriptionPlans() {
        return axios
            .get(`/payments/subscriptionPlans`)
            .then(response => {
                return response.data
            })
            .catch(err => {
                console.log(err)
            });
    },
    getPAYGTokenPrice() {
        return axios
            .get(`/payments/paygTokenPrice`)
            .then(response => {
                return response.data
            })
            .catch(err => {
                console.log(err)
            });
    },
    getVenuePaymentCards({ commit }, params) {
        console.log('getVenuePaymentCards commit', commit);
        return axios
            .get(`/payments/venues/${params.venue_id}/paymentCards`)
            .then(response => {
                return response.data
            })
            .catch(err => {
                console.log(err)
            });
    },
    getVenuePaymentCardsNotExpired({ commit }, params) {
        console.log('getVenuePaymentCardsNotExpired commit', commit);
        return axios
            .get(`/payments/venues/${params.venue_id}/nonExpiredPaymentCards`)
            .then(response => {
                return response.data
            })
            .catch(err => {
                console.log(err)
            });
    },
    getVenueDefaultPaymentCard({ commit }, params) {
        console.log('getVenueDefaultPaymentCard commit', commit);
        return axios
            .get(`/payments/venues/${params.venue_id}/defaultPaymentCard`)
            .then(response => {
                return response.data
            })
            .catch(err => {
                console.log(err)
            });
    },
    updateVenueBillingDetails({ commit }, params) {
        console.log('updateVenueBillingDetails commit', commit);
        return axios
            .put(`/update-venue/${params.venue_id}/billing-details`, params)
            .then(response => {
                return response.data
            })
            .catch(err => {
                console.log(err)
            });
    },
    useVenueTokenForGigBooking({ commit }, params) {
        console.log('useVenueTokenForGigBooking commit', commit);
        return axios
            .put(`/payments/tokens/${params.gig_id}`)
            .then(response => {
                return response.data
            })
    },
    reinstateGigToken({ commit }, params) {
        console.log('reinstateGigToken commit', commit);
        return axios
            .put(`/payments/tokens/${params.gig_id}/reinstate`)
            .then(response => {
                return response.data
            })
    },
    getVenuePaymentCard({ commit }, params) {
        console.log('getVenuePaymentCard commit', commit);
        return axios
            .get(`/payments/paymentCards/${params.card_id}`)
            .then(response => {
                return response.data
            })
            .catch(err => {
                console.log(err)
            });
    },
    getVenueActiveSub({ commit }, params) {
        console.log('getVenueActiveSub commit', commit);
        return axios
            .get(`/payments/venue/${params.venue_id}/subscriptions/active`)
            .then(response => {
                return response.data
            })
            .catch(err => {
                console.log(err)
            });
    },
    checkTokensFullyPaid({ commit }, params) {
        console.log('checkTokensFullPaid commit', commit);
        return axios
            .get(`/payments/tokens/${params.payment_log_id}/paid-tokens`)
            .then(response => {
                return response.data
            })
            .catch(err => {
                console.log(err)
            });
    },
    checkFullyPaidSuccess({ commit }, params) {
        console.log('checkFullyPaidSuccess commit', commit);
        return axios
            .get(`/payments/tokens/${params.payment_log_id}/paid-success`)
            .then(response => {
                return response.data
            })
            .catch(err => {
                console.log(err)
            });
    },
    getFailedPaymentsLogs(){
        return axios
            .get(`/payments/user/failedPayments`)
            .then(response => {
                return response.data
            })
            .catch(err => {
                console.log(err)
            });
    },
    clearVenueFailedPayments({ commit }, params) {
        console.log('clearVenueFailedPayments commit', commit);
        return axios
            .put(`/payments/venues/${params.venue_id}/clearFailedVenuePayments`, params)
            .then(response => {
                return response.data
            })
            .catch(err => {
                console.log(err)
            });
    },
    getRyftVenueList(){
        return axios
            .get(`/payments/venues?payment_process=ryft`)
            .then(response => {
                return response.data
            })
            .catch(err => {
                console.log(err)
            });
    },
    getVenueActiveTokens({ commit }, params) {
        console.log('getVenueActiveTokens commit', commit);
        return axios
            .get(`/payments/venue/${params.venue_id}/tokens/active`)
            .then(response => {
                return response.data
            })
            .catch(err => {
                console.log(err)
            });
    },
    // eslint-disable-next-line no-unused-vars
    deletePaymentCard({ commit }, params) {
        return axios
            .delete(`/payments/card/${params.card_id}/deletePaymentCard`)
            .then(response => {
                return response.data
            })
            .catch(err => {
                console.log(err)
            });
    },
    // eslint-disable-next-line no-unused-vars
    updateCardToDefault({ commit }, params) {
        return axios
            .post(`/payments/card/${params.card_id}/updateCardToDefault`)
            .then(response => {
                return response.data
            })
            .catch(err => {
                console.log(err)
            });
    },
    // eslint-disable-next-line no-unused-vars
    getGigListCreditUsed({ commit }, params) {
        return axios
            .get(`/payments/venue/${params.venue_id}/tokens/used`, { params })
            .then(response => {
                return response.data
            })
            .catch(err => {
                console.log(err)
            });
    },
    getRyftVenuePendingSubscriptions(){
        return axios
            .get(`/payments/venues/pending-payment-venues`)
            .then(response => {
                return response.data
            })
            .catch(err => {
                console.log(err)
            });
    },
    // eslint-disable-next-line no-unused-vars
    activateRyftVenuePendingSubscription({ commit }, params){
        return axios
            .put(`/payments/venue/subscriptions/${params.venue_id}/activate-pending`, { params })
            .then(response => {
                return response
            })
            .catch(err => {
                return err
            });
    },
   
};

const getters = {
};

export default {
    namespaced: true,
    state,
    mutations,
    actions,
    getters
};
