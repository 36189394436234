<template>
    <div>         

      <InlineNotification class="mb-4 ml-4 bg_guitarist" Heading="Your booked gigs">
        <template slot="body"> 

          <DictionaryContent :hookId="8" :dynamicUrl="'artist/gigs'">
            <p>Check out your GigPig bookings. They’re all listed here and scheduled in your calendar too. Double-check any instructions by clicking ‘view booking’.</p>
            <p>If you need to cancel any of these bookings, contact the venue straight away by phone.</p>
          </DictionaryContent>

        </template> 
      </InlineNotification>

      <div class="ml-4 flex flex-col">

      <div>

        <div class="flex flex-row my-4 ">
          <t-toggle name="past_gigs" v-model="show_past_gigs" @change="loadData" :checked="false" class="mr-4" />
          <label><span v-if="show_past_gigs">Including</span> <span v-else>Excluding</span> past gigs</label>
        </div>

        <div class="-my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
          <div
            class="py-2 align-middle inline-block min-w-full sm:px-6 lg:px-8"
          >


             <div v-if="booked_gigs == null">
                <NoDataMsgWrapper>Fetching booked gigs...</NoDataMsgWrapper>
              
            </div>

            <div
              class="max-w-4xl shadow overflow-hidden border-b border-gray-200 sm:rounded-lg"   v-if="booked_gigs && booked_gigs.length > 0"
            >
              <table class="min-w-full divide-y divide-gray-200 text-left"  >
                <thead class="bg-gray-50">
                  <tr>
                    <th scope="col" class="relative px-6 py-3">
                      <span class="sr-only">Edit</span>
                    </th>
                    <th
                      scope="col"
                      class="relative px-6"
                    >
                      Venue
                    </th> 
                    <th scope="col" class="relative px-6 py-3"> 
                        Gig
                    </th>
                    <th scope="col" class="relative px-6 py-3"> 
                        Date
                    </th>
                    <th scope="col" class="relative px-6 py-3"> 
                        Fee
                    </th>
                  </tr>
                </thead>
                <tbody class="bg-white divide-y divide-gray-200">
                  <tr v-for="(bid, index) in booked_gigs" :key="index" :row="bid" class="relative hover:bg-gray-100" :class="bid.cancelled || bid.gig.cancelled ? 'bg-red-100' : ''"> 
                      <td class="p-6">
                        <router-link class="" :to="'/artist/bookings/' + bid.id">
                         <span class="text-gp_pink-default-500">View Booking</span> 
                        </router-link>
                        <div>
                          <p v-if="bid.gig.cancelled || bid.cancelled" class="inline-flex items-center px-2 py-0.5 rounded text-xs font-medium bg-white uppercase text-red-800">Cancelled</p>    
                        </div>
                        <div v-if="bid.cancellation_reason && (bid.gig.cancelled || bid.cancelled)" class="mt-2">
                          <p class="inline-flex items-center py-0.5 rounded text-xs font-bold">Cancellation reason:</p>         
                          <p class="inline-flex items-center rounded text-xs">{{ bid.cancellation_reason }}</p>                     
                        </div>
                      </td>
                      <td class="p-6">      
                         {{bid.gig.venue.name}}<br/>
                         {{bid.gig.venue.address.town}} 
                         <a :href="'tel:' + bid.gig.venue.telephone">Tel: {{bid.gig.venue.telephone}}</a>
                      </td>
                      <td class="p-6">
                          <span class="text-indigo-600">{{bid.artist.name}}</span><br/>
                          {{bid.gig.name}}
                        <span v-if="bid.gig.cancelled || bid.cancelled || bid.gig.status === 'CANCELLATION_REQUESTED_BY_ARTIST'" class="text-red-500 text-xs font-bold rounded-3xl">Cancelled</span>
                      </td>
                      <td class="p-6">                             
                          {{  fullDate(bid.gig.start) }}     
                     </td>
                      <td class="p-6" >                         
                     
                        <span v-if="bid.fee && bid.fee > 0" class="inline-flex items-center px-2 py-0.5 rounded text-xs font-medium bg-green-100 text-green-800">
                        {{bid.fee}}   
                        </span>                           
                      </td>
                  </tr>
                </tbody>
              </table>
            </div> 
            <div v-else-if="booked_gigs">
                <NoDataMsgWrapper>No booked gigs for now.</NoDataMsgWrapper>
              
            </div>
          </div>
        </div>
      </div>
      </div>
  <!--
        <div v-if="debug">
          {{ convertedRows() }}
        </div>
-->
    </div>
</template>


<script type="text/javascript">


import NoDataMsgWrapper from '@/components/ui/messages/NoData.vue';
import { apiComputed } from '@/state/helpers.js';
import fullDate from  '@/utils/format-date-full.js'; 
import InlineNotification from '../../../components/notifications/inline.vue';
import NProgress from 'nprogress';
import {
  TToggle
} from 'vue-tailwind/dist/components';
import DictionaryContent from '../../../components/dictionary/DictionaryContent.vue';

export default {
  name: 'ArtistBookings',
  components:
  {
    InlineNotification,
    TToggle,
    NoDataMsgWrapper,
    DictionaryContent
},
  data() { 
      return {
        show_past_gigs: false,
        booked_gigs: null
      }
  },
  computed: {
    ...apiComputed
  }, 
  mounted(){
    
    this.loadData();
		
  },
  methods: { 
    fullDate,
    loadData(){
      this.isLoading = true;

      NProgress.start();

      this.$store.dispatch('artistBids/getFutureBookedOffers')
        .then((resp) => {
          this.booked_gigs = !this.show_past_gigs ? this.booked_gigs = resp.filter(f => f.gig && !f.gig.is_past) : this.booked_gigs = resp.filter(f => f.gig);
          
          return this.booked_gigs.sort((a,b) => {
            return new Date(a.gig.start) - new Date(b.gig.start)
          });

        }).finally(() => {
          this.isLoading = false;
          NProgress.done();
        }); 
    },
    convertedRows() {
      let rows = []

      if (this.artistbids != null) {
        this.artistbids.filter(f => f.gig != null).forEach(bid => {
            if (bid.artist_final_accepted)
            {
              console.log(bid.gig.is_past === false);
              if ((!this.show_past_gigs && bid.gig.is_past === false) || this.show_past_gigs === true)
              {
                 rows.push(bid);
              }
            }


        });
      }

      return rows.sort((a, b) => {         
          return new Date(a.gig.start) -  new Date(b.gig.start) 
       });
      //return rows
    }
  }
};

</script>
