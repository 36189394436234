<template>

	<div>

		<InlineNotification Heading="Completed gigs" class="ml-4 bg_guitarist">
			<template slot="body">
				<p>Once a gig is completed it will appear in this section. If you are happy that the gig took place and all the details are correct, you just need to sign it off. The invoice will then move to the “Billing and Invoices” section.</p>
				<p>If something changed on the gig (artists played for longer/turned up late) click on “Amend fee” and make the necessary changes.</p>

				<p class="font-bold">Please note, once you sign the invoice off you can’t amend the details.</p>
			</template>
		</InlineNotification>
     
	<PageDetails>
    
	<div class="lg:max-w-6xl">

	<div class="space-y-8 mb-16">

			<div class="ml-4 mt-12 flex-row lg:flex lg:space-x-24 space-y-6 lg:space-y-0">
				<div>
					<div class="flex space-x-2 items-center">
						<OfficeBuildingIcon class="text-gp_pink-default-500"/>
						<span class="font-black">Venues</span>
					</div>
					<div class="w-80 mt-4">
						<MultiselectFilter
						:options="compVenues"
						:selectedOptionProps="selectedVenues"
						:searchable="true"
						:multiple="true"
						:taggable="false"
						:close-on-select="false"
						:show-labels="false"
						:displayText="venueDisplayText"
						@optionSelected="venueSelected"
						@optionDeselected="venueDeselected"
						:placeHolder="'Filter by venue'"
						:track-by="'id'"
						></MultiselectFilter>
					</div>
				</div>
			</div>

			<div class="xl:flex xl:space-x-32 pb-4 pt-2 ml-4">
				<DateFilter
					:dateStartProp="dateFilterStart"
					:dateEndProp="dateFilterEnd"
					@dateFilterChange="dateFilterChange"
				/>
			</div>

			<div class="ml-4 my-12 flex-row lg:flex lg:space-x-24 space-y-6 lg:space-y-0">
				<div>
					<div class="flex space-x-2 items-center">
					<TagIcon class="text-gp_pink-default-500"/>
					<span class="font-black">Gig Tags</span>
					</div>
					<div class="w-80 mt-4">
					<TRichSelect 
						multiple
						v-model="setTagFilters"
						ref="iconselect"  
						class="col-span-12 lg:col-span-5 cursor-pointer"
						:close-on-select="false" valueAttribute="id"  textAttribute="name"
						:options="venue_custom_tags"
						placeholder="Filter by custom tag" 
					>  
						<template slot="option" slot-scope="{ index, isHighlighted, isSelected, className, option }">                                 
						<div class="flex gap-4 py-2">
							<component :is="icons[option.raw.icon]" class="ml-3"/>  {{ option.raw.name }} 
						</div>
						</template>
					</TRichSelect> 
					</div>
				</div>
			</div>

			<div v-show="isGenre" class="flex flex-row my-4 ml-4">
				<t-toggle name="show_signed_off_gigs" v-model="toggleSignedOff" class="mr-4"/>
				<label>Show signed-off gigs</label>
			</div>	

			<button v-show="!isLoading && hasData" 
				@click="exportCSV()" 
				:loading="loadingExports"
				:disabled="loadingExports"
				class="cta icon bg-black px-8 ml-4 flex items-center"><DocumentTextIcon class="w-5 h-5 mr-2" />Export to CSV ({{ excludeCSV }})</button>
		</div>

	
	<template v-if="isLoading">
		<div class="p-8 flex justify-center items-start">
			<!-- no need for loading state as using NProgress -->
		</div>
	</template>

	<template v-else-if="!isLoading && !hasData">
		<NoDataMsgWrapper>
			No gigs require sign-off. Signed-off gigs can be found under <router-link class="text-gp_pink-default-500" to="/invoices">'Billing and invoices'</router-link>
		</NoDataMsgWrapper>				
	</template>

	<template v-else>
		<div class="py-2 bg-gp_black-default-500 rounded-lg max-w-5xl flex items-center space-x-44 uppercase text-gray-200 text-sm font-medium">
			<input ref="selectall" type="checkbox" @change="selectAll" class="ml-6 bg-gray-100" />
			<div class="w-52 pl-14 flex space-x-3 invisible xl:visible">
				<div class="bg-gp_pink-default-400 w-[2px] h-5 rounded-lg"/>
				<span>Gig Details</span>
			</div>
			<div class="hidden xl:flex space-x-3 pl-16">
				<div class="bg-gp_pink-default-400 w-[2px] h-5 rounded-lg"/>        
				<span>Artist</span>
			</div>
		</div>

		<div class="flex flex-col lg:flex-row py-8 max-w-5xl border my-3 rounded-xl shadow-lg bg-white" v-for="(bid, index) in bids" :key="'bid.id' + index">
			<div class="flex">
				<div class="flex flex-row justify-center items-center ml-6">
					<div class="w-4 md:w-8">
						<input v-if="bid.signed_off_at === null" ref="check" :id="bid.id"  type="checkbox" @change="toggleOffer($event.target.checked, bid.id)" />
					</div>
					<div class="space-y-8 md:space-y-4 ml-6">
						<div class="flex space-x-2 items-center">
							<EyeIcon @click="$router.push('/gigs/' + bid.gig.id)" class="w-5 h-5 text-gp_pink-default-500 cursor-pointer"/>
							<router-link :to="'/gigs/' + bid.gig.id"  class="text-gp_pink-default-500 text-md font-extralight hidden md:block">View gig</router-link>
						</div>
						<div v-if="bid.invoice" class="flex space-x-2 items-center">
							<DocumentTextIcon @click="generateInvoice(bid)" class="w-5 h-5 text-gp_pink-default-500 cursor-pointer"/>
							<p v-if="bid.invoice"  class="text-gp_pink-default-500 text-md font-extralight cursor-pointer hidden md:block"  @click="generateInvoice(bid)">Preview invoice</p>
						</div>
						<p v-if="!bid.invoice" class="text-sm font-extralight">Invoice not generated</p>
						<div v-if="!bid.signed_off_at" class="flex space-x-2 items-center">
							<PencilAltIcon @click="editBidFee(bid)" class="w-5 h-5 text-gp_pink-default-500 cursor-pointer" />
							<p  v-if="!bid.signed_off_at" class="text-gp_pink-default-500 text-md font-extralight cursor-pointer hidden md:block" @click="editBidFee(bid)">Amend fee</p>
						</div>

						<div v-show="hasTagAccess" class="flex space-x-2 items-center">
							<TagIcon v-if="!bid.signed_off_at" @click="openUpdateTagsModal(index, bid.gig)" class="w-5 h-5 text-gp_pink-default-500 cursor-pointer" />
							<p  v-if="!bid.signed_off_at" class="text-gp_pink-default-500 text-md font-extralight cursor-pointer hidden md:block" @click="openUpdateTagsModal(index, bid.gig)">Manage tags</p>
						</div>
					</div>
				</div>

				<div class="w-96 flex space-x-5 ml-6 md:ml-12 pr-2 md:pr-0">
					<div>
						<StatusBadge :status="bidStatus(bid)" class="w-32 lg:w-44 ml-2" />  
						<div class="mt-4 text-md space-x-3">
							<div class="bg-gp_pink-default-400 w-[2px] h-full rounded-lg"/> 
							
							<span class="font-bold">{{bid.gig.venue.name}}</span>
							<span class="block">{{bid.gig.name}}</span>
							<span class="block">{{  fullDate(bid.gig.start) }}</span>
							<div class="flex items-center space-x-2 mt-2">
								<ClockIcon class="w-4 h-4"/>
								<span class="block text-sm">{{timeAgo(bid.gig.start)}} ago</span>
							</div>
							<div class="my-3 space-x-3 flex items-center">
								<div v-if="bid.gig.custom_tags.length > 0" class="flex items-center justify-center px-3 py-1 rounded-3xl space-x-2 bg-black ">
									<component :is="icons[firstCustomTagIconName(bid.gig.custom_tags[0])]" class="w-4 h-4" :style="{ color: firstCustomTagIconColour(bid.gig.custom_tags[0]) }"/>
									<span class="text-white text-xs">{{ firstCustomTagName(bid.gig.custom_tags[0]) }}</span>
								</div>
								<div>
									<span class="relative inline-flex flex-col items-center group" v-if="bid.gig.custom_tags.length > 0">
										<span v-if="bid.gig.custom_tags.length > 1"
										class="text-sm font-medium underline underline-offset-2 cursor-pointer"
										>+{{ bid.gig.custom_tags.length - 1 }} more</span>
										<div class="absolute z-10 bottom-0 flex-col items-center hidden mb-6 group-hover:flex">
										<span class="p-4 bg-black rounded-sm shadow-lg w-48">
											<ul class="list-none">
											<li v-for="tag in bid.gig.custom_tags" :key="tag.id" class="text-white flex space-x-4 items-center space-y-1">
												<component :is="icons[tag.icon]" class="w-5 h-5" :style="{ color: tag.icon_colour }"/>
												<span>{{ tag.name }}</span>
											</li>
											</ul>
										</span>
										<div class="w-3 h-3 -mt-2 rotate-45 bg-black"></div>
										</div>
									</span>
                </div>
               </div>

							<div class="block xl:hidden items-center mt-6">
								<div class="flex items-center space-x-4">
									<hr class="w-4 h-[2px] bg-gp_pink-default-400 border-0 rounded">
									<strong class="block text-sm">Booked Artist</strong>
								</div>
								<div class="flex space-x-4 mt-6">
									<div class="flex flex-col justify-center items-center">
										<img class="rounded-full h-12 w-12 object-cover border-2 border-gp_pink-default-400" :src="getArtistsImage(bid)" />
									</div>
									<div class="">
										<span class="block font-bold">{{ getArtistsName(bid) }}</span>
										<div class="flex items-center space-x-2">
											<span class="block text-md">Fee: £{{bid.fee}}</span>
										</div>
										<span v-if="bid.display_amends"><strong>Confirmed - Amended</strong></span>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>

			<div class="ml-3 hidden xl:block w-80">
				<div class="inline-flex items-center justify-center w-full">
					<hr class="w-64 h-[2px] bg-gp_pink-default-400 border-0 rounded">
					<div class="absolute px-3 -translate-x-1/2 bg-white uppercase font-medium text-xs text-gray-600">
						<span class="uppercase font-medium text-xs text-gray-600">Booked artist</span>
					</div>
				</div>

				<div class="flex space-x-4 mt-6">
					<div class="flex flex-col justify-center items-center">
						<img class="rounded-full h-12 w-12 object-cover border-2 border-gp_pink-default-400" :src="getArtistsImage(bid)" />
					</div>
					<div class="">
						<span class="block font-bold">{{ getArtistsName(bid) }}</span>
						<div class="flex items-center space-x-2">
							<span class="block text-md">Fee: £{{bid.fee}}</span>
						</div>
						<span v-if="bid.display_amends"><strong>Confirmed - Amended</strong></span>
					</div>
				</div>
			</div>
		</div>

		<div class="max-w-5xl shadow overflow-hidden border-b">
			<Pagination
				@changePage="(n) => paginate_currPage = n"
				:paginate_from="paginate_from"
				:paginate_to="paginate_to"
				:paginate_total="paginate_total"
				:paginate_currPage="paginate_currPage"
				:paginate_lastPage="paginate_lastPage"
			></Pagination>
		</div>
	</template>

	<div v-show="selectedBids.length > 0" class="action-bar justify-end">  
        <button @click="openModal" 
				:loading="isSigningOff"
				:disabled="signOffButtonDisabled"
				class="cta icon bg-indigo-600">{{ this.signOffButtonLabel }}</button>
    </div>

</div> 
	<AmendCustomGigTag ref="updateGigTags" v-on:updatedTag="updatedTags($event)"/> 

    <SignoffArtists ref="signoffModal" :toSignOff="selectedBids" @rateArtists="rateArtists()" v-on:signoff="signoffArtists()" v-on:cancel="closeModal()" />
	<ArtistMultiRating ref="ratingModal" :toRate="toRate"/>
    <InvoiceGeneration ref="invoice" :invoiceItems="[invoiceItem]" />
		<template v-if="selectedBid">
			<AmendBidFee 
				:is-open="showAmendBidFeeModal"
				:bid="selectedBid"
				@closed="cancelModal"
				@updated="onBidFeeUpdated"
			/>
		</template>
		
	</PageDetails>
</div>

</template>

<script type="text/javascript">
import PageDetails from '@/components/ui/wrappers/PageDetails.vue'
import NoDataMsgWrapper from '@/components/ui/messages/NoData.vue';
import fullDate from  '@/utils/format-date-full.js'  
import timeAgo from  '@/utils/format-date-timeago.js'  
import InlineNotification from '../../components/notifications/inline.vue';   
import InvoiceGeneration from '../../components/invoice/wrapper.vue';
import SignoffArtists from '../../components/modal/SignoffArtists.vue';  
import NProgress from 'nprogress';
import AmendBidFee from '../../components/modal/gigs/AmendBidFee.vue'
import StatusBadge from '../../components/iconsets/StatusBadge.vue'
import { getStatusFromType as getBidStatus } from '@/state/models/bidStatus'
import Pagination from "@/components/elements/Pagination.vue";
import {pastGigsMethods} from "@/state/helpers";
import DateFilter from '../../components/filters/DateFilter.vue';
import { DateTime } from 'luxon';
import { EyeIcon, DocumentTextIcon, PencilAltIcon, ClockIcon, TagIcon, OfficeBuildingIcon } from "@vue-hero-icons/outline"
import AmendCustomGigTag from '../../components/modal/gigs/AmendCustomTags.vue';
import  * as icons from "@vue-hero-icons/outline" 
import {mapState} from "vuex";
import { TRichSelect } from 'vue-tailwind/dist/components';
import client from '@/utils/client.js'
import {TToggle} from 'vue-tailwind/dist/components';
import { apiMethods } from '@/state/helpers.js'
import MultiselectFilter from '@/components/filters/MultiSelectFilter.vue'
import ArtistMultiRating from '../../components/modal/ArtistMultiRating.vue';

export default {
	name: "PastGigs",
  components:{
    Pagination,
		PageDetails,
		NoDataMsgWrapper,
		InlineNotification, 
		InvoiceGeneration, 
		SignoffArtists,
		AmendBidFee,
		StatusBadge,
		DateFilter,
		EyeIcon, DocumentTextIcon, PencilAltIcon, ClockIcon, TagIcon, OfficeBuildingIcon,
		AmendCustomGigTag,
		TRichSelect,
		TToggle,
		MultiselectFilter,
		ArtistMultiRating
	},
  data() { 
      return {
		venue_custom_tags: [],
		setTagFilters: [],
		icons: icons,
		selectedIndex: null,
        paginate_from: null,
        paginate_to: null,
        paginate_total: null,
        paginate_currPage: 1,
        paginate_lastPage: null,
		selectedBids: [],
		toRate: [],
		invoiceItem: null,
		mobile: false,
		showAmendBidFeeModal: false,
		selectedBid:null,
		rows: [],
		compVenues: [],
		selectedVenues: [{id: 'all', name: 'All venues'}],
		isLoading:false,
		dateFilterStart: this.getCalendarStartDate(),
		dateFilterEnd: this.getCalendarEndDate(),
		toggleSignedOff: false,
		loadingExports: false,
     }
  },
  watch: {
    paginate_currPage() {
      this.load();
    },
	dateFilterStart() {
	localStorage.setItem('completedGigsStartDate', this.dateFilterStart)
	},
	dateFilterEnd() {
		localStorage.setItem('completedGigsEndDate', this.dateFilterEnd)
	},
	setTagFilters(){
		this.load();
	},
	toggleSignedOff() {
		this.load();
	},
	selectedVenues(){
        if (this.selectedVenues.length === 0) {
          this.selectedVenues = [{id: 'all', name: 'All'}];
        }
        this.paginate_currPage = 1;
        this.load();
	}, 
  },
  mounted(){
		let query = window.matchMedia("(max-width:768px)");

		if (query.matches){
			this.mobile = true;
		} else {
			this.mobile = false;
		}

		client.get('custom-tags').then(response => (this.venue_custom_tags = response.data.data))

		client.get('venues-filter-list').then((response) => { this.compVenues = [
            { name:"All venues", id:"all" },
            ...response.data.data
          ];})

		this.load()
  },
  computed: {
	...mapState({
		userRoles: (state) => state.user.rolesPermissionsSlugs,
	}),
	venueParams() {
      if (!this.compVenues.length || (this.compVenues[0].id === 'all' && this.compVenues.length === 1))
        return [];

      if (!this.selectedVenues.length || (this.selectedVenues[0].id === 'all' && this.selectedVenues.length === 1))
        return [];

      const venues = this.selectedVenues[0].id === 'all' ? this.compVenues.slice(1) : this.selectedVenues;
      return venues.map((venue) => venue.id);
    },
	excludeCSV(){
      return this.selectedBids.length === 0 ? 'All' : this.selectedBids.length;
    },
    hasTagAccess()
    {
        if (this.userRoles && this.userRoles.length > 0) {
            return this.userRoles[0] == 'full-access';
        } else {
            return null; 
        }
    },
	userData(){
      return this.$store.getters['user/userData']
    },
    isGenre() {
      return this.userData && this.userData.email.includes('genremusic')
    },
	venueDisplayText() {
      return this.selectedVenues[this.selectedVenues.length-1].name
    },
    numberSelected(){ 
			return this.selectedBids.length 
		},
		getArtistsName(){
			return (gig) => gig && gig.artist ? gig.artist.name : "no artists details"
		},
		getArtistsImage(){
			return (gig) => gig && gig.artist.image && gig.artist.image.url ? gig.artist.image.url : "no image"
		},
		hasData(){
			if(this.rows){
				if(this.rows.length){
					return true;
				}
			}
			return false;
		},
		signOffButtonDisabled(){
			return !this.numberSelected > 0
		},
		signOffButtonLabel(){
			return `Sign off selected performances (${ this.numberSelected })`
		},
		isSigningOff(){
			return false;
		},
		bids(){
			if(this.rows){
				return this.rows
			}else{
				return []
			}
		},
		bidStatus(){
			return (bid) => getBidStatus(bid.status)
		},
		checkDevice(){
			return this.mobile
		}
  },
  methods: {
	...apiMethods,
    ...pastGigsMethods,
    fullDate,
    timeAgo,
	rateArtists() {
		this.$refs.ratingModal.toggleModal();
	},
	venueSelected(option) {
      if (option.id === 'all') {
        this.selectedVenues = [{id: 'all', name: 'All venues'}];
      } else {
        const index = this.selectedVenues.findIndex((element) => element.id === 'all');
        if (index !== -1) {
          this.selectedVenues.splice(index, 1);
        }
        this.selectedVenues.push(option);
      }
    },
	venueDeselected(option) {
      if (option.id !== 'all') {
        const index = this.selectedVenues.findIndex((element) => element.id === option.id);
        if (index !== -1) {
          this.selectedVenues.splice(index, 1);
        }
      }
    },
	exportCSV(){
		NProgress.start();
		this.loadingExports = true;

		if (this.selectedBids.length) {
			this.bookingExport(this.selectedBids.map(m => m))
				.then(() => {
					NProgress.done();
					this.loadingExports = false;
				});
		} else {
			this.getPastGigsWithTrashed({page: this.paginate_currPage, paginate: false, dateFilterStart: this.dateFilterStart, toggleSignedOff: this.toggleSignedOff, custom_tags: this.setTagFilters, dateFilterEnd: this.dateFilterEnd})
				.then((response) => {
					this.bookingExport(response.map(f => f))
						.then(() => {
							NProgress.done();
							this.loadingExports = false;
						});
				})
		}
    },
	firstCustomTagName(val){
		return val.name
	},
	firstCustomTagIconName(val){
		return val.icon
	},
	firstCustomTagIconColour(val){
		return val.icon_colour
	},
	openUpdateTagsModal(index, gig) {
		this.selectedIndex = index;
		this.$refs.updateGigTags.updateGigTag(gig);
	},
	updatedTags(event) {
      this.rows[this.selectedIndex].gig.custom_tags = event;
    },
    load() {
      this.isLoading = true;
      NProgress.start();
      this.getPastGigsWithTrashed({
		page: this.paginate_currPage, 
		paginate: true,  
		dateFilterStart: this.dateFilterStart, 
		toggleSignedOff: this.toggleSignedOff, 
		custom_tags: this.setTagFilters, 
		dateFilterEnd: this.dateFilterEnd,
		venue_ids: this.venueParams,
		})
          .then((response) => {
            this.rows = response.data;
            this.paginate_from = response.meta.from
            this.paginate_to = response.meta.to
            this.paginate_total = response.meta.total
            this.paginate_lastPage = response.meta.last_page

            NProgress.done();
            this.isLoading = false;
          })
          .catch(err => {
            console.log(err);
            NProgress.done();
            this.isLoading = false;
          })
    },
    selectAll($event){
			const checked = $event.target.checked;
			this.$refs.check.forEach((c) => {
				c.checked = checked;
				this.toggleOffer(c.checked, c.id);
			});
    },
    generateInvoice($bid){
      this.invoiceItem = $bid;
      this.$nextTick(function(){
        this.$refs.invoice.generate();
      });
    },
		cancelModal(){
			this.showAmendBidFeeModal = false;
		},
		editBidFee($bid){
			this.selectedBid = $bid;
			this.showAmendBidFeeModal = true;
		},
		onBidFeeUpdated($bid){
			this.selectedBid = $bid;
			this.cancelModal();
			NProgress.start();
			this.$store.dispatch('pastGigs/syncUpdatedBid', this.selectedBid)
				.then((response) => {
					this.rows = response;
					NProgress.done();
				})
				.catch(err => {
					console.log(err);
					NProgress.done();
				})
		},
    toggleOffer: function(isOn, $bidid){ 
      if (isOn === true)
      {
        this.selectedBids.push($bidid.toString());
      }
      else 
      {
		this.selectedBids.map((f, index) => {
			if (f.toString() === $bidid.toString()) {
				this.selectedBids.splice(index, 1);
			}
		})
      }
    }, 
    openModal(){
		this.toRate = [];
		this.toRate = this.rows.filter((e) => { return this.selectedBids.includes(e.id.toString())});
        this.$refs.signoffModal.open();
    },
	dateFilterChange(dateObject) {
		this.dateFilterStart = dateObject.dateFilterStart;
		this.dateFilterEnd = dateObject.dateFilterEnd;
		this.load();
	},
	getCalendarStartDate() {
		const completedGigsStartDate = localStorage.getItem('completedGigsStartDate')
		if (completedGigsStartDate) {
		return completedGigsStartDate
		} else {
		return DateTime.now().minus({months: 1}).toISODate()
		}
  },
  getCalendarEndDate() {
      const completedGigsEndDate = localStorage.getItem('completedGigsEndDate')
    if (completedGigsEndDate) {
      return completedGigsEndDate
    } else {
      return DateTime.now().toISODate()
    }
    }
  }
};

</script>