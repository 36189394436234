<template>

	<div>
		<div class="rounded-md bg-green-50 p-4 max-w-4xl mt-4 mb-24">
			<div class="flex items-center mb-1">
				<CheckCircleIcon class="h-5 w-5 text-green-400" />
				<p class="mt-0 ml-3 text-base"><strong>{{ getGeneratedLabel }}</strong></p>
			</div>

			<p class="mt-0 ml-8 mb-4 text-base text-green-600">Note: The dates below must be applied to take effect.  Uncheck the dates that you do not wish to apply.</p>


						
			<div v-if="parsedDates" class="ml-8 flex flex-col">
				<div 
					v-for="item in parsedDates" 
					:key="item.id" 
					class="flex items-center mb-4"
				>
					<SwitchToggle 
						:enabled="item.checked"
						:disabled="isLoading"
						@updateEnable="onDateChecked"
						:id="item.id" 
						:label="getDateLabel( item.date )"
					/>
				</div>
			</div>
			
			<div class="action-bar">   
				<GPButton 
					@click.native="cancelRepeats" 
					:disabled="isLoading"
					color="gray"
					size="lg"
				>Cancel</GPButton>       
				<GPButton 
					@click.native="showConfirmation" 
					:disabled="getConfirmDisabled" 
					:loading="isLoading"
					size="lg"
				>{{ getConfirmLabel }}</GPButton>                               
			</div>
			<NotificationSmall ref="notification" />
			<TopUpOptions ref="topupmodal" />
		</div>
	</div>
	

</template>


<script> 
import NotificationSmall from '@/components/notifications/small.vue'; 
import pluralize from  '@/utils/pluralize.js'
import { DateTime } from 'luxon';
import { CheckCircleIcon } from "@vue-hero-icons/solid"
import GPButton from '../../ui/buttons/Button.vue';
import SwitchToggle from '../../ui/form/SwitchToggle.vue';
import TopUpOptions from '@/components/modal/venues/subscriptions/TopUpOptions.vue'

export default {
    name: "GigRepeat",
    components:{
			CheckCircleIcon,
			GPButton,
			SwitchToggle,
			NotificationSmall,
			TopUpOptions
		}, 
		props:{
			repeatDates:{
				type:Array,
				required: true,
			},
			frequency:{},
			frequencyLabel:{
				type:String,
				required: true,
			},
			isMultiple:{
				type:Boolean,
				required: true,
				default: false,
			},
			repeatPublished:{
				type:Boolean,
				required: true,
				default: false,
			},
			repeatBooked:{
				type:Boolean,
				required: true,
				default: false,
			},
			startDate:{}
		},
		emits:["cancel", "confirm"],
    data (){
			return {  
				isLoading:false,
				parsedDates:this.parseDates(this.repeatDates),
				selectedVenue: this.$store.state.account.venueInfo
			}
    }, 
		computed: { 
			getDateLabel(){
				return date => DateTime ? DateTime.fromISO(date).toUTC().toFormat('DDDD HH:mm') : ''
				// return date => DateTime ? DateTime.fromISO(date).toUTC().toFormat('DDDD HH:mm') : ''
			},
			getGeneratedLabel(){
				let count = pluralize(this.frequency, this.frequencyLabel);
				let startDate = DateTime.fromISO(this.startDate).toUTC().toFormat('DDDD')
				if(this.isMultiple){
					count = pluralize(this.repeatDates.length, this.frequencyLabel);
					startDate = DateTime.fromISO(this.repeatDates[0]).toUTC().toFormat('DDDD')
				}
				return `Generated ${ count } starting ${ startDate }`;
			},
			getConfirmDisabled(){
				return this.parsedDates.filter(i => !i.checked).length === this.parsedDates.length;
			},
			getConfirmLabel(){
				return this.isLoading ? 'Confirming gigs' : 'Confirm gigs'
			},
    },
		watch:{
			repeatDates(updatedRepeatDates){
				this.parsedDates = this.parseDates(updatedRepeatDates);
			}
		},
    methods:{
			parseDates(dates){
				return dates.map(d => {
					const key = String(new Date(DateTime.fromISO(d).toUTC()).getTime())
					return{
						id:key,
						date:d,
						checked:true
					}
				})
			},
			onDateChecked(id){
				const date = this.parsedDates.filter(d => d.id === id)[0]
				date.checked = !date.checked;
			},
			cancelRepeats(){
				// emit
				this.$emit('cancel');
			},
			showConfirmation(){

				if (this.selectedVenue && this.selectedVenue.payment_process == 'ryft' && this.selectedVenue.payment_type !== 'payg_charged_and_billed_monthly'
					&& (this.repeatBooked || this.repeatPublished) &&  (this.selectedVenue.credits_remaining < parseInt(this.frequency))
				) {
					this.$refs.notification.createNotification(`Insufficient gig credits to proceed.`, true);
					this.$refs.topupmodal.openWithVenueInfo({ id: this.selectedVenue.id });
					return;
				}

				if(!this.getConfirmDisabled){
					const confirmedDates = this.parsedDates
						.filter(i => i.checked)
						.map(i => i.date);
					// emit
					this.$emit('confirm', { confirmedDates });
				}
				
			},
    }
}
</script>