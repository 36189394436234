<template>
  <PageDetails>
    <h1 class="text-2xl font-bold leading-7 text-gray-900 sm:leading-9 sm:truncate">
      Edit Venue {{ isLoading ? "-" : "-" }}
      <span v-if="venue">{{venue.name}}</span>
    </h1>
    <div class="lg:max-w-5xl">
      <VenueTabs :venue-id="venueId" current-route="manage-subscription"/>

      <template v-if="isLoading">
        <div class="p-8 flex justify-center items-start">
          <PageSpinner
            tailwind-diameter="8"
            tailwind-border="4"
            tailwind-color="pink-500"
          />
        </div>
      </template>

      <template v-if="!isLoading">
        <div class="mt-6">
          <span class="font-medium text-2xl">{{ venue.name }} Venue Credits and Subscriptions</span>
        </div>

        <div v-show="subscriptions.length" class="mt-6">
          <div class="gris grid-cols-1 lg:grid-cols-2 flex items-center justify-between">
            <div>
              <div class="flex space-x-3">
                <button @click="downloadSelectedInvoices()" type="button" class="flex items-center space-x-2 py-1 px-5 me-2 text-sm font-medium text-gp_pink-default-500 focus:outline-none bg-white rounded-lg border border-gp_pink-default-500 hover:bg-gp_pink-default-500 hover:text-white focus:z-10 focus:ring-4 focus:ring-gray-100">
                  <DownloadIcon class="w-4 h-4 hidden xl:block"/>
                  <span>Download selected ({{ selectedSubscriptions.length }})</span>
                </button>

                <button @click="downloadAllInvoices()" type="button" class="flex items-center space-x-2 py-2.5 px-5 me-2 text-sm font-medium text-gp_black-default-500  focus:outline-none bg-white rounded-lg border border-black hover:bg-black hover:text-white focus:z-10 focus:ring-4 focus:ring-gray-100">
                  <DownloadIcon class="w-4 h-4 hidden xl:block"/>
                  <span>Download all</span>
                </button>
              </div>
            </div>

            <div v-show="venueInfo && !venueInfo.has_subscription" class="h-12 w-auto flex space-x-3">
              <div  class="hidden lg:flex xl:space-x-12  items-center xl:bg-gp_black-default-500 px-4 rounded-md text-white">
                <div>
                  <p class="hidden xl:block text-sm">Want to be billed monthly and book months in advance?</p>
                </div>
                <div @click="openSubscriptionsModal()" class="space-x-3 flex items-center bg-gp_pink-default-500 hover:bg-gp_pink-default-400 px-4 py-4 xl:py-2 rounded-md text-white cursor-pointer">
                  <span class="text-sm">View subscriptions</span>
                </div>
              </div>
            </div>
          </div>

          <div class="mt-8 h-10 w-full bg-gray-100 border border-gray-200 shadow flex items-center rounded-t-md">
            <div class="ml-4 w-4">
              <input ref="selectall" type="checkbox" @change="selectAllSubscriptions($event.target.checked)" />
            </div>
            <div class="ml-16 w-80 font-medium text-gray-600 hidden lg:block">
              <span>Invoice</span>
            </div>
            <div class="ml-8 w-68 font-medium text-gray-600 hidden lg:block">
              <span>Credits</span>
            </div>
            <div class="ml-4 w-64 font-medium text-gray-600 hidden lg:block">
              <span>Total</span>
            </div>
            <div class="ml-8 w-full font-medium text-gray-600 hidden lg:block">
              <span>Purchase information</span>
            </div>
          </div>
          <div v-for="(sub, index) in subscriptions" 
              :key="index"
              class="w-full h-32 border border-gray-200 flex items-center overflow-x-auto"
          >
            <div class="w-auto flex items-center">
              <div class="ml-4 w-4">
                <input v-if="sub.payment_successful" ref="check" v-bind:id="sub.id" type="checkbox" @change="selectSubscription($event.target.checked, sub)" />
              </div>
              <div class="ml-16 w-40  font-medium text-gray-600">
                <button v-show="sub.payment_successful"  @click="downloadInvoice(sub)" type="button" class="flex items-center space-x-2 py-1.5 px-3 me-2 text-sm font-medium text-black focus:outline-none bg-gray-100/20 rounded-lg border border-gray-200 hover:bg-black hover:text-white focus:z-10 focus:ring-4 focus:ring-gray-100">
                  <DownloadIcon class="w-4 h-4 hidden xl:block"/>
                  <span>Download</span>
                </button>
              </div>
            </div>
            <div class="w-auto flex items-center">
              <div class="ml-4 w-32 font-medium text-gray-600">
                <p v-show="sub.token_quantity" class="text-xs text-gray-600">+{{sub.token_quantity}} credit<span v-show="sub.token_quantity && sub.token_quantity != 1">s</span></p>
              </div>
              <div class="ml-2 w-32 font-medium text-gray-600">
                <span class="text-xs text-gray-600">£{{ sub.amount }}</span>
              </div>
              <div class="ml-6 w-36 font-medium text-gray-600">
                <div>
                  <span class="text-xs font-black text-black">{{ returnLastFourDigits(sub.card) }}</span>
                  <p class="text-xs font-black text-black">{{ returnCardNickname(sub.card) }}</p>
                </div>
              </div>

              <div class="ml-6 w-auto font-medium text-gray-600 space-y-3">
                <p v-if="sub.payment_successful" class="text-xs text-gray-600"><span class="text-xs font-black text-black">Purchased</span> {{ formattedDate(sub.created_at) }}</p>
                <p v-if="!sub.payment_successful" class="text-xs text-gray-600"><span class="text-xs font-black text-red-500">Attempted</span> {{ formattedDate(sub.created_at) }}</p>
                <div v-if="sub.card">
                  <span class="text-xs font-black text-black">Purchased by</span>
                  <p class="text-xs text-gray-600">{{ returnPurchasedBy(sub.card) }}</p>
                </div>
                <p v-if="sub.mit_payment_id" class="text-xs text-gray-600"><span class="text-xs font-black text-black">Ref</span> {{ sub.mit_payment_id.substring(0, 15) }}...</p>
              </div>
            </div>
          </div>
        </div>
        <div v-show="!subscriptions.length && (venueInfo && !venueInfo.has_subscription)" class="mt-6">
          <div class="h-12 w-auto flex space-x-3">
              <div  class="hidden lg:flex xl:space-x-12  items-center xl:bg-gp_black-default-500 px-4 rounded-md text-white">
                <div>
                  <p class="hidden xl:block text-sm">Want to be billed monthly and book months in advance?</p>
                </div>
                <div @click="openSubscriptionsModal()" class="space-x-3 flex items-center bg-gp_pink-default-500 hover:bg-gp_pink-default-400 px-4 py-4 xl:py-2 rounded-md text-white cursor-pointer">
                  <span class="text-sm">View subscriptions</span>
                </div>
              </div>
          </div>
          <div class="p-8 mt-32 flex justify-center items-start">
            <span>No subscription purchases have been made.</span>
          </div>
        </div>
      </template>
      <SubscriptionUpgrade ref="subupgrademodal" />
      <InvoiceGeneration ref="invoice" :invoiceItems="invoiceItems" />
    </div>
  </PageDetails>
</template>

<script>
import PageDetails from "@/components/ui/wrappers/PageDetails.vue";
import PageSpinner from "../../components/ui/spinner/PageSpinner.vue";
import VenueTabs from "../../components/venues/VenueTabs.vue";
import {paymentsMethods} from "@/state/helpers";
import { DownloadIcon } from "@vue-hero-icons/outline"
import SubscriptionUpgrade from '../../components/modal/venues/subscriptions/SubscriptionUpgrade.vue'
import InvoiceGeneration from '../../components/ryft_invoices/wrapper.vue';
import NProgress from 'nprogress';
import { mapGetters } from "vuex";

export default {
  components: {
    PageDetails,
    PageSpinner,
    VenueTabs,
    DownloadIcon,
    SubscriptionUpgrade,
    InvoiceGeneration
  },
  props: ["venueId"],
  data() {
    return {
      isLoading: false,
      subscriptions: [],
      selectedSubscriptions: [],
      invoiceItems: []
    };
  },
  computed: {
    ...mapGetters('account', ['getVenueInfo']),
    venueInfo() {
      return this.$store.state.account.venueInfo;
    },
    hasData() {
      return true;
    },
    venue() {
      return this.$store.getters['venue/currentVenue']
    },
    currentSubscription() {
      return this.venue !== null ? this.venue.active_subscription : undefined;
    },
  },
  watch: {
		getVenueInfo: {
      immediate: true,
      handler(newValue, oldValue) {
        console.log("Venue info updated", newValue, oldValue);
        this.loadData();
      }
    }
	},
  methods: {
    ...paymentsMethods,
    formattedDate(sub_date) {
      const date = new Date(sub_date.replace(" ", "T"));
      const year = date.getFullYear();
      const month = String(date.getMonth() + 1).padStart(2, '0'); 
      const day = String(date.getDate()).padStart(2, '0');
      
      return `${year}-${month}-${day}`;
    },
    returnLastFourDigits(card) {
      return card ? '**** ' + card.last_4_digits : ''
    },
    returnCardNickname(card) {
      return card ? card.alias : ''
    },
    returnPurchasedBy(payment) {
      return payment ? payment.email : ''
    },
    openSubscriptionsModal() {
      this.$refs.subupgrademodal.openWithVenueInfo({ id: this.venue.id });
    },
    selectAllSubscriptions($checked) {
      this.selectedSubscriptions = [];

      this.$refs.check.forEach((c) => {
          c.checked = $checked;
          let $sub = this.subscriptions.find(sub => sub.id == c.id);
          this.selectSubscription($checked, $sub);
      });
    },
    selectSubscription: function(isOn, $sub){
      if (isOn) {
        this.selectedSubscriptions.push($sub);
      } else {
        let removeIndex = this.selectedSubscriptions.indexOf($sub);
        if (removeIndex !== -1){
          this.selectedSubscriptions.splice(removeIndex, 1);
        }
      }
    },
    downloadInvoice(sub){
        this.invoiceItems = [sub];
				this.$refs.invoice.generate();
    },
    downloadSelectedInvoices() {
      this.invoiceItems = this.selectedSubscriptions;
			this.$refs.invoice.generate();
    },
    downloadAllInvoices() {
      NProgress.start();

      this.selectAllSubscriptions(true);

      setTimeout(() => {
        this.downloadSelectedInvoices();
        NProgress.done();
      }, 1000);
    },
    loadData() {
      NProgress.start();
        this.isLoading = true;

        this.getVenueSubscriptionsInvoices({ venue_id: this.venueId })
          .then((response) => {
              this.subscriptions = response.data;
          })
          .finally(() => {
            this.isLoading = false;
            NProgress.done();
          });
    }
  },
  mounted() {
      this.loadData();
  } 
};
</script>
