<template>
    <div v-if="modal_open" transition="fadein" class="fixed z-10 inset-0 overflow-y-auto duration-300 ease-in transition-all modal" ref="modal" aria-labelledby="modal-title" role="dialog" aria-modal="true">
        <SubmitMITPayment
            ref="paymentModal"
            :show-modal="showMITModal"
            :MITPublicKey="pubKey"
            :MITClientSecret="MITClientSecret"
            :customerEmail="ryftCustomerEmail"
            @showMITPaymentModal="value => showMITModal = value"
            @formSubmitted="formSubmitted"
            @formFailed="formFailed"
            @cardSubmissionComplete="cardSubmissionComplete"
            @cardNumberIsValid="value => cardNumberIsValid = value"
        >
        </SubmitMITPayment>
        <div class="flex items-center justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
            <div class="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" aria-hidden="true">
            </div>
            <span class="hidden sm:inline-block sm:align-middle sm:h-screen" aria-hidden="true">&#8203;</span>
            <transition appear enter-active-class="animate__animated animate__fadeInUp" leave-active-class="animate__animated animate__fadeOutDown">        
                <div :class="modalStyling">
                    <div class="flex justify-end mb-6 text-gp_pink-default-500">
                        <XIcon @click="toggleModal()" class="hover:text-gp_pink-default-400 cursor-pointer"/>
                    </div>
                    <div v-show="step == 1"  class="grid grid-cols-1 md:grid-cols-2 gap-12">
                        <!-- LEFT COLUMN -->
                        <div class="h-auto w-full">
                            <div>
                                <h3 class="block font-bold text-gp_pink-default-500 uppercase" id="modal-title">Subscription options</h3>
                                <p v-show="venue.name" class="uppercase text-sm mt-2 text-gray-100 font-medium">{{ venue.name }}</p> 
                            </div>

                            <div class="mt-6">
                                <div class="grid grid-cols-2">
                                    <div>
                                        <p class="text-md mt-2 text-gray-100">Approximate Gig's booked a week</p> 
                                    </div>
                                    <div class="flex items-center justify-center mt-2">
                                        <div class="w-1/2 py-2 px-3 bg-white border border-gray-200 rounded-lg dark:bg-neutral-900 dark:border-neutral-700" data-hs-input-number="">
                                            <div class="flex justify-between items-center gap-x-5">
                                                <div class="grow">
                                                    <input 
                                                        v-model="cred_qty"
                                                        class="w-full p-0 bg-transparent border-0 text-gray-800 focus:ring-0" 
                                                        style="appearance: textfield; -moz-appearance: textfield; -webkit-appearance: textfield; position: relative;"
                                                        type="number" 
                                                        aria-roledescription="Number field" 
                                                        min="0"
                                                        value="1" 
                                                        data-hs-input-number-input=""
                                                    >
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div v-show="cred_qty < 15" class="grid grid-cols-5 mt-12">
                                    <div class="space-y-8 col-span-3">
                                        <p class="text-md mt-2 text-gray-200 font-light">Amount to pay today</p> 
                                        <p class="text-md mt-2 text-gray-200 font-light">Monthly charge</p> 
                                        <p class="text-md mt-2 text-gray-200 font-light">Credits today</p> 
                                    </div>
                                    <div v-show="this.cred_qty > 0" class="space-y-8 place-items-end col-span-2">
                                        <p class="text-md mt-2 text-gray-200 font-light">£{{ monthlyCharge }} + VAT</p> 
                                        <p class="text-md mt-2 text-gray-200 font-light">£{{ monthlyCharge }} + VAT</p> 
                                        <p class="text-md mt-2 text-gray-200 font-light">{{ creditsToday }}</p> 
                                    </div>
                                    <div v-show="this.cred_qty <= 0" class="space-y-8 place-items-end col-span-2">
                                        <p class="text-md mt-2 text-gray-200 font-light">N/A</p> 
                                        <p class="text-md mt-2 text-gray-200 font-light">N/A</p> 
                                        <p class="text-md mt-2 text-gray-200 font-light">N/A</p> 
                                    </div>
                                </div>
                                <div v-show="cred_qty >= 15" class="mt-12 h-32 w-full p-4 text-white rounded-xl bg-gray-400/30">
                                    <span class="font-semibold">Enterprise level</span>
                                    <p class="text-xs mt-3 text-justify">For venues that book a large volume of gigs a week, offering premium support, bespoke feature requests and more. (COPY TBC)</p>
                                </div>
                            </div>

                            <div v-show="cred_qty < 15" class="w-full mt-10">
                                <div v-if="!paymentCards" @click="upgradeNow()" class="flex items-center w-full cursor-pointer py-2 bg-gp_pink-default-500 hover:bg-gp_pink-default-400 rounded-lg px-6 text-white">
                                    <span class="font-semibold w-11/12 uppercase">Upgrade now</span>
                                    <ArrowNarrowRightIcon stroke="white" class="w-6 h-6"/>
                                </div>
                                <div v-if="paymentCards" @click="chooseCard()" class="flex items-center w-full cursor-pointer py-2 bg-gp_pink-default-500 hover:bg-gp_pink-default-400 rounded-lg px-6 text-white">
                                    <span class="font-semibold w-11/12 uppercase">Pay now</span>
                                    <ArrowNarrowRightIcon stroke="white" class="w-6 h-6"/>
                                </div>
                            </div>

                            <div v-show="cred_qty >= 15" class="w-full mt-10">
                                <a href="mailto:letstalk@gigpig.uk?subject=Enterprise%20package" class="flex items-center w-full cursor-pointer py-2 bg-gp_pink-default-500 hover:bg-gp_pink-default-400 rounded-lg px-6 text-white">
                                    <span class="font-semibold w-11/12 uppercase">Get in touch</span>
                                    <ArrowNarrowRightIcon stroke="white" class="w-6 h-6"/>
                                </a>
                            </div>

                            <div v-show="venue && (!venue.pay_via_venue_accs_department || venue.payments_classification == 'small')">
                                <div class="space-y-2 mt-10">
                                    <p class="text-xs text-gray-200 font-bold">GigPig will pay your artists on your behalf.  Payment will automatically be taken when you sign-off your gig.</p>
                                </div>

                                <div class="space-y-2 mt-4">
                                    <p class="text-xs text-gray-200">This is subject to a card processing fee:</p>
                                    <p class="text-xs text-gray-200">Visa/Mastercard 1.4% of artist fee.</p>
                                    <p class="text-xs text-gray-200">Amex: 1.4% + 10p of artist fee.</p>
                                </div>

                                <div class="space-y-2 mt-4">
                                    <p class="text-xs text-gray-200">For example, if your artist fee is £200, you will be charged £202.94 on a Visa.</p>
                                </div>
                            </div>

                            <div v-show="selectedVenue && selectedVenue.payment_type == 'payg'" class="space-y-2 mt-10">
                                <p @click="openPAYGModal()" class="underline text-xs text-gray-200 hover:text-gray-100 font-bold cursor-pointer">&lt; Back to PAYG options</p>
                            </div>

                        </div>

                        <!-- RIGHT COLUMN -->
                        <div v-show="cred_qty < 15" class="h-auto w-full flex justify-center items-center">
                            <div class="justify-center">
                                <div class="flex justify-end mr-10">
                                    <p :class="showSavings">Save £{{ monthlySavings }} a month!</p>
                                </div>
                                <div class="bg-gray-400/30 px-6 py-4 rounded-xl mt-4 text-justify">
                                    <div class="flex justify-between font-medium">
                                        <p class="text-md mt-2 text-gray-100">Top up discount!</p>
                                        <p v-show="cred_qty && cred_qty < 15" class="text-md mt-2 text-gray-100">£{{ perGigShow }} per gig</p>
                                    </div>

                                    <p class="text-sm mt-6 text-gray-100 font-light">You’ll receive all your tokens for the year ahead today to allow you to book ahead of time.</p>
                                    <p class="text-sm mt-6 text-gray-100 font-light">Concerned you’ll need more?  You can still top-up on the spot at a discounted rate when you run low on credits.</p>
                                </div>

                                <div class="space-y-2 mt-4 flex justify-end">
                                    <a href="mailto:letstalk@gigpig.uk?subject=Enterprise%20package" class="underline text-xs text-gray-200 hover:text-gray-100 font-bold cursor-pointer">Need more options?  Get in touch with our sales team</a>
                                </div>
                            </div>
                        </div>
                        <div v-show="cred_qty >= 15" class="h-auto w-full">
                            <div>
                                <h3 class="block font-bold text-gp_pink-default-500 uppercase" id="modal-title">Enterprise Solutions Tailored for Your Business</h3>
                            </div>
                            <div class="h-96 overflow-y-auto pr-6">
                                <div class="mt-6 space-x-3 text-justify">
                                    <p class="text-xs text-gray-100"><strong>GigPig</strong> is your premier partner for comprehensive enterprise solutions designed to meet the unique needs of larger organisations. 
                                        Our suite of services ensures seamless management and superior outcomes for all your events.
                                    </p>
                                </div>

                                <div class="space-y-6 text-justify mt-6">
                                    <div class="space-y-2">
                                        <strong class="text-sm text-gp_pink-default-500">Support for Artist Sourcing</strong>
                                        <p class="text-xs text-gray-100">Simplify your talent acquisition process with GigPig. We provide robust support for artist sourcing, helping you find the right talent for your events while you retain control over the final selection.</p>
                                    </div>
                                    <div class="space-y-2">
                                        <strong class="text-sm text-gp_pink-default-500">Custom Analytics & Reporting</strong>
                                        <p class="text-xs text-gray-100">Make informed decisions with our advanced analytics and reporting tools. We can deliver detailed insights into your events' performance, enabling you to measure success and refine strategies for future engagements.</p>
                                    </div>
                                    <div class="space-y-2">
                                        <strong class="text-sm text-gp_pink-default-500">Dedicated Development Resources</strong>
                                        <p class="text-xs text-gray-100">At GigPig, we understand that one size doesn't fit all. Our dedicated development team works closely with you to build and customize solutions that align seamlessly with your business needs, ensuring optimal functionality and impact.</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div v-show="step == 2"  class="h-auto w-full">
                        <div class="h-auto w-full">
                            <div>
                                <h3 class="block font-bold text-gp_pink-default-500 uppercase" id="modal-title">BILLING ADDRESS</h3>
                                <p v-show="venue.name" class="uppercase text-sm mt-2 text-gray-100 font-medium">{{ venue.name }}</p> 
                            </div>

                            <div class="mt-6">
                                <div class="flex items-center justify- space-x-3 pt-8 text-gray-300 text-sm font-semibold">
                                    <span>Same as venue address</span>
                                    <t-toggle v-model="same_as_address"/>
                                </div>
                                
                                <div v-show="!same_as_address">
                                    <div class="pt-4 space-y-1 text-sm font-semibold">
                                        <span class="text-gray-100 text-sm font-light">Billing address line one</span>
                                        <input type="text" 
                                                v-model="billing_address.address_line_1"
                                                placeholder="Address line one"
                                                id="billing_address_line_1" 
                                                class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5" 
                                                required 
                                        />
                                    </div>
                                    <div class="pt-4 space-y-1 text-sm font-semibold">
                                        <span class="text-gray-100 text-sm font-light">Billing address line two (Optional)</span>
                                        <input type="text" 
                                                v-model="billing_address.address_line_2"
                                                placeholder="Address line two"
                                                id="billing_address_line_2" 
                                                class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5" 
                                        />
                                    </div>
                                    <div class="pt-4 space-y-1 text-sm font-semibold">
                                        <span class="text-gray-100 text-sm font-light">Billing address city</span>
                                        <input type="text" 
                                                v-model="billing_address.city"
                                                placeholder="Address city"
                                                id="billing_address_town" 
                                                class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5" 
                                                required 
                                        />
                                    </div>
                                    <div class="pt-4 space-y-1 text-sm">
                                        <span class="text-gray-100 text-sm font-light">Billing address postcode</span>
                                        <input type="text" 
                                                v-model="billing_address.post_code"
                                                placeholder="Address postcode"
                                                id="billing_address_post_code" 
                                                class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5" 
                                                required 
                                        />
                                    </div>
                                    <div class="pt-4 space-y-1 text-sm font-semibold">
                                        <span class="text-gray-100 text-sm font-light">Billing address country</span>
                                        <input type="text" 
                                                v-model="billing_address.country"
                                                placeholder="Address country"
                                                id="billing_address_county" 
                                                class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5" 
                                                required 
                                        />
                                    </div>
                                </div>
                                <div class="pt-4 space-y-1 text-sm font-semibold w-full">
                                    <span class="text-gray-100 text-sm font-light">Currency</span>
                                    <TRichSelect
                                        id="currencies"
                                        name="currencies"
                                        v-model="currency"
                                        :options="currencies"
                                        @change="pickCurrency"
                                        value-attribute="id"
                                        text-attribute="display_name"
                                        class="w-full block focus:ring-indigo-500 focus:border-indigo-500 shadow-sm sm:text-sm border-gray-300 rounded-lg"
                                    />
                                </div>
                            </div>

                            <div class="w-full mt-10">
                                <GPButton @click.native="addCardDetails()"
                                                :disabled="isSubmitting"
                                                :loading="isSubmitting"
                                                color="pink"
                                                class="w-full justify-between"
                                >
                                    <span class="font-semibold">Add card details</span>
                                    <ArrowNarrowRightIcon stroke="white" class="w-6 h-6"/>
                                </GPButton> 
                            </div>
                        </div>
                    </div>

                    <div v-show="step == 3"  class="h-auto w-full">
                        <div class="h-auto w-full">
                            <div>
                                <h3 class="block font-bold text-gp_pink-default-500 uppercase" id="modal-title">Save a card</h3>
                                <p v-show="venue.name" class="uppercase text-sm mt-2 text-gray-100 font-medium">{{ venue.name }}</p> 
                            </div>
                            <div class="mt-6">
                                <div>
                                    <div class="pt-4 space-y-1 text-sm font-semibold">
                                        <span class="text-gray-100 text-sm font-light">Card nickname</span>
                                        <input type="text" 
                                                v-model="billing_address.card_nickname"
                                                placeholder="e.g. Head office card"
                                                id="billing_address_line_1" 
                                                class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5" 
                                                required 
                                        />
                                    </div>
                                    <p class="text-xs text-gray-200 mt-2 w-full font-light">Naming your card will help distinguish different cards used by your business.</p>
                                </div>
                                <div class="mt-6">
                                    <span class="text-gray-100 text-sm font-light">Recipient email for this transaction receipt</span>
                                    <input type="text" 
                                                v-model="billing_address.recipient_email"
                                                placeholder="accounts@myvenue.com"
                                                id="billing_address_line_1" 
                                                class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 mt-2" 
                                                required 
                                        />
                                </div>
                                <div class="grid grid-cols-5 mt-6">
                                    <div class="col-span-1 mt-4">
                                        <t-toggle v-model="confirm_charge"/>
                                    </div>
                                    <div class="col-span-4">
                                        <span class="text-gray-200 text-xs text-justify font-light">I confirm my card will be charged by GigPig Ltd for the purpose of token purchases by those with access to my venue.</span>
                                    </div>
                                </div>
                                <div class="mt-4">
                                    <span class="text-gray-200 text-xs text-justify font-light">
                                        Your card details will be securly saved for future use by for your venues booking team, so they don’t need access to your card beyond this point.
                                    </span>
                                </div>
                            </div>

                            <div class="w-full mt-10">
                                <GPButton @click.native="addCardDetailsFinal()"
                                                :disabled="isSubmitting"
                                                :loading="isSubmitting"
                                                color="pink"
                                                class="w-full justify-between"
                                >
                                    <span class="font-semibold">Add card details</span>
                                    <ArrowNarrowRightIcon stroke="white" class="w-6 h-6"/>
                                </GPButton> 
                            </div>
                        </div>
                    </div>

                    <div v-show="step == 4">
                        <div class="h-auto w-full">
                            <div>
                                <h3 class="block font-bold text-gp_pink-default-500 uppercase" id="modal-title">Choose payment card</h3>
                                <p v-show="venue.name" class="uppercase text-sm mt-2 text-gray-100 font-medium">{{ venue.name }}</p> 
                            </div>

                            <div class="mt-6">
                                <span class="text-gray-100 text-sm font-light">Choose payment card</span>
                                <TRichSelect
                                    id="currencies"
                                    name="currencies"
                                    v-model="chosenPaymentCard"
                                    :options="paymentCards"
                                    value-attribute="id"
                                    text-attribute="alias"
                                    class="mt-2 w-full block focus:ring-indigo-500 focus:border-indigo-500 shadow-sm sm:text-sm border-gray-300 rounded-lg"
                                >
                                    <template slot="option" slot-scope="{ index, isHighlighted, isSelected, className, option }">                                 
                                        <div class="flex py-2 ml-3">
                                            <component :is="paymentCards[option]" class="ml-3"/>  
                                            <p class="font-bold">{{ option.raw.alias }} </p>
                                        </div>
                                        <div class="flex py-1 ml-3 hover:border-b text-xs">
                                            <component :is="paymentCards[option]" class="ml-3"/>  
                                            <div class="flex items-center justify-between gap-x-8">
                                                <div>
                                                    <p class="text-xs">Expires {{ option.raw.expiry_month }}/{{ option.raw.expiry_year }}</p>
                                                </div>
                                                <div>
                                                    <p class="text-xs font-bold">**** {{ option.raw.last_4_digits }}</p>
                                                </div>
                                            </div>
                                        </div>
                                    </template>
                                </TRichSelect>

                                <div class="my-12">
                                    <span @click="addNewCard()" class="underline text-xs text-gp_pink-default-500 hover:text-gp_pink-default-400 font-bold cursor-pointer">Add a new payment card</span>
                                </div>
                            </div>

                            <div class="w-full mt-10">
                                <GPButton @click.native="confirmCard()"
                                                :disabled="isSubmitting"
                                                :loading="isSubmitting"
                                                color="pink"
                                                class="w-full justify-between"
                                >
                                    <span class="font-semibold">Confirm payment card</span>
                                    <ArrowNarrowRightIcon stroke="white" class="w-6 h-6"/>
                                </GPButton> 
                            </div>
                        </div>
                    </div>

                    <div v-show="step == 5" class="h-auto w-full">
                        <!-- LEFT COLUMN -->
                        <div class="h-auto w-full">
                            <div>
                                <h3 class="block font-bold text-gp_pink-default-500 uppercase" id="modal-title">Confirm Subscription Purchase</h3>
                                <p v-show="venue.name" class="uppercase text-sm mt-2 text-gray-100 font-medium">{{ venue.name }}</p> 
                            </div>

                            <div class="mt-6">
                                
                                <div class="space-y-3">
                                    <p class="block font-bold text-gray-100">Using {{ this.billing_address.card_nickname }} card</p>
                                    <p class="block font-bold text-gray-100">Purchasing {{ creditsToday }} credit<span v-show="cred_qty > 1">s</span></p>
                                </div>

                                <div class="grid grid-cols-5 mt-12">
                                    <div class="space-y-8 col-span-3">
                                        <p class="text-md mt-2 text-gray-200 font-light">Amount to pay today</p> 
                                        <p class="text-md mt-2 text-gray-200 font-light">Monthly charge</p> 
                                        <p class="text-md mt-2 text-gray-200 font-light">Credits today</p> 
                                    </div>
                                    <div class="space-y-8 place-items-end col-span-2">
                                        <p class="text-md mt-2 text-gray-200 font-light">£{{ monthlyCharge }} + VAT</p> 
                                        <p class="text-md mt-2 text-gray-200 font-light">£{{ monthlyCharge }} + VAT</p> 
                                        <p class="text-md mt-2 text-gray-200 font-light">{{ creditsToday }}</p> 
                                    </div>
                                </div>
                            </div>

                            <div class="w-full mt-10">
                                <GPButton @click.native="payNow()"
                                                :disabled="isSubmitting"
                                                :loading="isSubmitting"
                                                color="pink"
                                                class="w-full justify-between"
                                >
                                    <span class="font-semibold">Pay now</span>
                                    <ArrowNarrowRightIcon stroke="white" class="w-6 h-6"/>
                                </GPButton> 
                            </div>
                        </div>
                    </div>

                    <div v-show="step == 6"  class="h-auto w-full">
                        <div>
                            <h3 class="block font-bold text-gp_pink-default-500 uppercase" id="modal-title">Payment complete</h3>
                            <p v-show="venue.name" class="uppercase text-sm mt-2 text-gray-100 font-medium">{{ venue.name }}</p> 
                        </div>
                        <div class="mt-6">    
                            <div class="space-y-3">
                                <p class="text-gray-100 text-sm">Your subscription has been created. A receipt invoice has been sent to your billing email.</p>
                            </div>
                        </div>
                        <div class="w-full mt-10">
                            <div @click="returnToPlatform()" class="flex items-center w-full cursor-pointer py-2 bg-gp_pink-default-500 hover:bg-gp_pink-default-400 rounded-lg px-6 text-white">
                                <span class="font-semibold w-11/12">Return to platform</span>
                                <ArrowNarrowRightIcon stroke="white" class="w-6 h-6"/>
                            </div>
                        </div>
                    </div>

                </div>
            </transition>        
        </div>
    </div>
</template>

<script>
import { ArrowNarrowRightIcon, XIcon } from "@vue-hero-icons/outline"
import { TToggle, TRichSelect } from 'vue-tailwind/dist/components'
import {paymentsMethods, apiMethods} from "@/state/helpers";
import GPButton from '../../../../components/ui/buttons/Button.vue';
import SubmitMITPayment from "@/components/MIT/SubmitMITPayment.vue";
import NProgress from 'nprogress';
import { DEFAULT_CURRENCY_ID } from "@/constants/contants";
import client from "../../../../utils/client"
import { mapActions, mapState } from "vuex";

export default
{
    name: "SubscriptionUpgrade", 
    data() {
        return {
            modal_open: false,
            cred_qty: 1,
            step: 1,
            same_as_address: false,
            confirm_charge: false,
            venue: [],
            currency: DEFAULT_CURRENCY_ID,
            currencies: [],
            paymentCards: [],
            payment_log_id: null,
            chosenPaymentCard: null,
            billing_address: {
                address_line_1: '',
                address_line_2: '',
                city: '',
                country: '',
                post_code: '',
                company_name: '',
                card_nickname: '',
                recipient_email: '',
            },
            subscriptionPlans: [],
            subscriptionSelected: [],
            cards: ['Card one', 'Card two'],
            base_currency: DEFAULT_CURRENCY_ID,
            cardId: null,
            ryftCustomerCreated: false,
            showMITModal: false,
            MITClientSecret: '',
            pubKey: process.env.VUE_APP_RYFT_PUBLIC_KEY,
            ryftCustomerEmail: null,
            cardNumberIsValid: false,
            cardButtonDisabled: false,
            isSubmitting: false,
            selectedVenue: {
                credits_remaining: '',
                id: null,
                name:  '',
                expiry_year:  '',
                expiry_month:  '',
                payment_process:  '',
                currency_id: null,
                payment_type:  '',
            }
        }
    },
    components: {
        ArrowNarrowRightIcon, XIcon,
        TToggle,
        GPButton,
        SubmitMITPayment,
        TRichSelect
    },
    computed: {
        ...mapState('account', ['venueInfo']),
        modalStyling() {
            return this.step == 1 ? 'inline-block align-bottom bg-gp_black-default-500 border border-gp_pink-default-400 rounded-3xl px-4 pt-5 pb-4 text-left shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-3xl sm:w-full sm:p-6' : 'inline-block align-bottom bg-gp_black-default-500 border border-gp_pink-default-400 rounded-3xl px-4 pt-5 pb-4 text-left shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-xl sm:w-full sm:p-6'
        },
        monthlyCharge() {
            return this.subscriptionSelected.monthly_charge;
        },
        creditsToday() {
            return this.subscriptionSelected.initial_token_quantity !== null ? this.subscriptionSelected.initial_token_quantity : this.subscriptionSelected.token_quantity;
        },
        perGigShow() {
            return this.cred_qty <= 8 ? parseFloat('9.95').toFixed(2) : parseFloat('5.82');
        },
        monthlySavings() {
            const PAYG_RATE = 15; // Cost per gig for PAYG 
            const STANDARD_SUB_RATE = 199; // Subscription cost for 2–5 gigs per week
            const STANDARD_ADDITION_RATE = 9.95;
            const STANDARD_GIGS_WEEK = 20;
            const ENTERPRISE_SUB_RATE = 349; // Subscription cost for 6+ gigs per week
            //const ENTERPRISE_ADDITION_RATE = 5.82;
            
            let gigs = this.cred_qty;

            let gigsPerYear = gigs * 52;
            let gigsPerMonth = gigsPerYear / 12;
            
            let pricePerMonth = PAYG_RATE * gigsPerMonth;
                         
            let amount = 0;
            
            if (gigs <= 4){
                    amount = STANDARD_SUB_RATE;
            }
            else if (gigs <= 8){
                    amount = STANDARD_SUB_RATE + 
                ((gigsPerMonth - STANDARD_GIGS_WEEK) * STANDARD_ADDITION_RATE);
            }
            else{     		
                    amount = ENTERPRISE_SUB_RATE;
            }
                
            let saving = pricePerMonth - amount;
 
            
            
            return saving.toFixed(2);
        },
        showSavings() {
            return this.cred_qty && (this.cred_qty > 3 &&  this.cred_qty < 15) ? 'block font-bold text-gp_pink-default-500' : 'invisible font-bold text-gp_pink-default-500'
        },
        currencyIsoCode() {
            return this.currencies.find(f => f.id === this.base_currency).iso_code;
        }, 
    },
    watch: {
        cred_qty(value) {
            if (value < 9) {
                this.subscriptionSelected = this.subscriptionPlans[0];
            } else {
                this.subscriptionSelected = this.subscriptionPlans[1];
            }
        },
        ryftCustomerCreated() {
            this.initRyftOnboarding();
        }
    },
    methods: {
        ...apiMethods,
        ...paymentsMethods,
        ...mapActions('account', ['getUserInfo']),
        formSubmitted() {
            NProgress.start();
        },
        formFailed() {
            NProgress.done();
        },
        pickCurrency(value) {
            this.currency = value
        },
        upgradeNow() {
            
            if (parseInt(this.cred_qty) < 1 || parseInt(this.cred_qty) >= 15) {
                this.$notify("Please input your required gigs per week.");
                return;
            }

            this.step = 2;
        },
        async toggleModal() {
            if (!this.isSubmitting) {
                await this.getUserInfo();
                this.step = 1;
                this.modal_open = !this.modal_open;

                if (this.modal_open) {
                    this.selectedVenue = this.$store.state.account.venueInfo
                } else {
                    this.cred_qty = 1;
                    this.selectedVenue = null;
                    this.chosenPaymentCard = null;
                }
            } else {
                this.$notify("Please await submission completion.");
            }
        },
        openPAYGModal() {
            this.toggleModal();
        },
        async returnToPlatform() {
            await this.getUserInfo().then(response => this.$store.dispatch('account/updateAccInfo', response))
            this.toggleModal();
            this.$emit('closePAYGModal');
        },
        chooseCard() {
            this.step = 4;
        },
        confirmCard() {

            this.isSubmitting = true;

            if (!this.chosenPaymentCard) {
                this.$notify("Please choose a valid payment card.");
                this.isSubmitting = false;
                return;
            }

            let card = this.paymentCards.find((f) => f.id === this.chosenPaymentCard);

            this.cardId = card.id;
            this.billing_address.card_nickname = card.alias;
            this.isSubmitting = false;
            this.step = 5;

        },
        addNewCard() {
            this.step = 2;
        },
        async openWithVenueInfo(venue_info) {

            const token = await this.$auth.getTokenSilently();
            client.get("currencies", { headers: { Authorization: `Bearer ${token}` } }).then(response => (this.currencies = response.data.data));

            await this.getPaymentModalVenueInfo({ venue_id : venue_info.id })
                .then((response) => {
                    this.venue = response.data;
                    this.currency = response.data.currency_id;
                })

            await this.getVenuePaymentCardsNotExpired({ venue_id: venue_info.id })
                .then((response) => {
                    this.paymentCards = response;
                })

            await this.getSubscriptionPlans()
                    .then((response) => {
                        this.subscriptionPlans = response.data;
                        this.subscriptionSelected = this.subscriptionPlans[0];
                    })

            this.toggleModal();
        },
        async addCardDetails() {

            this.isSubmitting = true;

            if (!this.same_as_address && (!this.billing_address.address_line_1  || !this.billing_address.city || !this.billing_address.country || 
                !this.billing_address.post_code)) 
            {
                this.$notify("Please provide all requested details.");
                this.isSubmitting = false;
                return;
            }

            let params = {
                same_as_address: this.same_as_address,
                billing_address: {
                    address_line_1: this.billing_address.address_line_1,
                    address_line_2: this.billing_address.address_line_2,
                    town: this.billing_address.city,
                    country: this.billing_address.country,
                    post_code: this.billing_address.post_code,
                },
                currency: this.currency,
                venue_id: this.venue.id
            };

            await this.updateVenueBillingDetails(params)
                .then(() => {
                    this.isSubmitting = false;
                    this.step = 3;
                })

        },
        addCardDetailsFinal() {

            if (!this.billing_address.card_nickname  || !this.billing_address.recipient_email)
            {
                this.$notify("Please provide all requested details.");
                return;
            }

            if (!this.confirm_charge)
            {
                this.$notify("Please accept the terms of use to continue.");
                return;
            }

            this.loadRyftSdk()
        },
        loadRyftSdk() {

            this.isSubmitting = true;

            console.log("submitting");
            this.createPaymentCard({
                default: 1, 
                email: this.billing_address.recipient_email,
                venue_id: this.venue.id,
                alias: this.billing_address.card_nickname,
            }).then(resp => {
                this.cardId = resp.data.id;
                this.ryftCustomerCreated = true;
                this.isSubmitting = false;
            }).catch(($ex) => {
                console.error("createPaymentCard exception:", $ex.response.data.message);
                this.$notify({
                    text: $ex.response.data.message + ": Please try again or contact support for further assistance.",
                    duration: 5000
                });
                this.isSubmitting = false;
            })
        },
        initRyftOnboarding() {
            this.createMerchantPaymentSession(
                {
                amount: 0,
                verifyAccount: true,
                currency: this.currencyIsoCode,
                email: this.billing_address.recipient_email,
                metadata: {verifyAccount: true, default: true, cardId: this.cardId.toString(), venueId: this.venue.id },
                paymentType: 'Unscheduled',
                venue_id: this.venue.id,
                venueId:this.venue.id
                // ryft_customer_id: this.form.ryft_customer_id
                }
            ).then((resp) => {
                this.MITClientSecret = resp.data.clientSecret
                this.ryftCustomerEmail = resp.data.customerEmail
                this.showMITModal = true
                this.isSubmitting = false;
            })
            .catch(() => {
                this.$notify("An error has occurred. Please try again or contact support for further assistance.");
                this.isSubmitting = false;
            });
        },
        cardSubmissionComplete() {
            this.$notify("Please wait while we verify your payment card information.");
            this.cardSubmissionCheck();
        },
        cardSubmissionCheck(){
            setTimeout(() => {
                this.getVenuePaymentCard({ card_id: this.cardId })
                    .then((response) => {
                        if (!response.data.merchant_id || !response.data.initial_payment_id) {
                            this.cardSubmissionCheck();
                        } else {
                            this.selectedVenue.expiry_month = response.data.expiry_month;
                            this.selectedVenue.expiry_year = response.data.expiry_year;
                            this.step = 5;
                            this.showMITModal = false;
                            NProgress.done();
                        }
                    })
                    .catch(() => {
                        this.$notify("An error has occurred. Please try again or contact support for further assistance.");
                        this.isSubmitting = false;
                    })
            }, 2000);
        },
        tokenPaidCheck() {

            NProgress.start();

            setTimeout(() => {
                this.checkTokensFullyPaid({ payment_log_id: this.payment_log_id })
                    .then((response) => {
                        if (response.data.length !== parseInt(this.creditsToday)) {
                            this.tokenPaidCheck();
                        } else {
                            this.$notify("Payment successful.");

                            if (this.selectedVenue !== null) {
                                this.$store.dispatch('account/updateVenueInfo', null)
                                this.selectedVenue.has_subscription = true;
                                this.selectedVenue.credits_remaining = Number(this.selectedVenue.credits_remaining) + Number(this.creditsToday);
                                this.$store.dispatch('account/updateVenueInfo', this.selectedVenue)
                                this.selectedVenue = null;
                            }

                            this.getUserAccInfo().then(response => this.$store.dispatch('account/updateAccInfo', response))
                            NProgress.done();
                            this.isSubmitting = false;
                            this.step = 6;

                            try
                            {
                                setTimeout(() => {
                                    window.dataLayer = window.dataLayer || []; //gtm ppc tracking
                                    window.dataLayer.push({'event': 'subscription_purchase'});
                                    if (window.f24){
                                        let resp = window.f24('event', 'wa_page_view', { loc:window.location.origin, CheckOutAmount: this.monthlyCharge});
                                        console.log('force24 tracking response:', resp);
                                    }
                                }, 2000);   
                            } catch (error){
                                console.error('Error tracking GTM/force24:', error);
                            }        
                            
                        }
                    })
                    .catch(() => {
                        this.$notify("An error has occurred. Please try again or contact support for further assistance.");
                        this.isSubmitting = false;
                    })
            }, 2000);
        },
        subPaidCheck() {
            NProgress.start();

            setTimeout(() => {
                this.checkFullyPaidSuccess({ payment_log_id: this.payment_log_id })
                    .then((response) => {

                        const paymentData = response.data[0];

                        if (paymentData.paid && !paymentData.failed) {
                            this.$notify("Payment successful. Crediting your account.");
                            NProgress.done();
                            this.tokenPaidCheck();
                        } else if (!paymentData.paid && paymentData.failed) {
                            this.$notify({
                              text: 'Your card has been declined.',
                              duration: 8000
                            });
                            this.isSubmitting = false;
                            NProgress.done();
                        } else if (!paymentData.paid && !paymentData.failed) {
                            this.subPaidCheck();
                        }

                    })
                    .catch(() => {
                        this.$notify("An error has occurred. Please try again or contact support for further assistance.");
                        this.isSubmitting = false;
                    })
            }, 2000);
        },
        payNow() {

            this.isSubmitting = true;

            let params = {
                subscription_id: this.subscriptionSelected.id,
                venue_id: this.venue.id,
                card_id: this.cardId
            }

            this.addSubscription(params)
                .then((response) => {
                    this.payment_log_id = response.payment_log_id;
                    this.subPaidCheck();
                })
                .catch(() => {
                    this.$notify("An error has occurred. Please try again or contact support for further assistance.");
                    this.isSubmitting = false;
                })

        },

    }
}

</script>


<style>
    * {
    scrollbar-width: thin;
    scrollbar-color: var(--secondary) var(--primary);
    }

    /* Chrome, Edge, and Safari */
    *::-webkit-scrollbar {
    width: 10px;
    }

    *::-webkit-scrollbar-track {
    background: var(--primary);
    border-radius: 5px;
    }

    *::-webkit-scrollbar-thumb {
    background-color: var(--secondary);
    border-radius: 14px;
    border: 3px solid var(--primary);
    }
</style>