<template>
  <div> 
    <Modal :show="showModal" @closeModal="close">
      
      <template #header><h3 class="text-xl font-bold leading-6 text-gray-900 block" id="modal-title"> GigPig will now handle your artist payments directly</h3></template>
      <template #header2> 
      </template>
      <template #default>
          <p class="block my-4">Your Artist fees will now be automatically processed from the card you used to book with. Once the gig is completed and signed off, it will leave your account and the artist invoice will be issued, saving you time on admin.</p>
                
          <p class="block mb-4">GigPig will pay artists 14 days after sign-off, allowing time to resolve any disputes.</p>               

          <p class="block mb-4">If you have any questions, <a class="text-gp_pink-default-400 font-bold" href="https://www.gigpig.uk/get-in-touch" target="_blank">get in touch</a> and the team will be happy to help.</p>

          <p class="text-xs">* Card fees apply on top of artist fee 1.4% + 10p Visa & Mastercard cards / 1.8% +10p Amex</p>
      </template>
      <template #buttons>&nbsp;</template>
    </Modal>
    <div  class="max-w-7xl px-4 mt-12 sm:px-6">
      <h1 class="text-2xl font-semibold text-gray-900 mb-5" v-if="$auth.user">
            Hi {{ $auth.user.name }} 
      </h1>
      <h2 class="text-base font-semibold mb-5">Notifications</h2>

      <div class="min-h-[200px]">
        <div v-if="isLoading" class="animate-pulse space-y-4">
          <div class="h-4 bg-gray-200 rounded w-3/4"></div>
          <div class="h-4 bg-gray-200 rounded w-1/2"></div>
          <div class="mt-8 space-y-3">
            <div class="h-20 bg-gray-200 rounded"></div>
            <div class="h-20 bg-gray-200 rounded"></div>
          </div>
        </div>

        <div v-else>
          <div v-if="notification_count > 0" class="mb-6">
            You have {{ notification_offers_needing_set_response.length > 0 ? formattedResponseCount : 'no' }} responses for action, 
            {{ notification_offers_invoice_to_pay.length > 0 ? formattedInvoiceCount : 'no' }} invoices needing sign-off and 
            {{ notification_offers_artist_cancellations.length > 0 ? formattedCancellationCount : 'no' }}
            {{ notification_offers_artist_cancellations.length == 0 || notification_offers_artist_cancellations.length > 1 ? 'cancellations' : 'cancellation'}} to respond to.
          </div>
          <div v-else-if="no_notifications === true" class="mb-6">
            You have no notifications at this time.
          </div>

          <div class="notification_actions lg:grid lg:grid-cols-2 lg:gap-6 space-y-4 lg:space-y-0">
            <transition-group 
              name="notification-list" 
              tag="div" 
              class="grid gap-4 content-start"
              v-if="notification_offers_artist_cancellations.length"
            >
              <AlertWithLink 
                v-for="$offer in notification_offers_artist_cancellations" 
                :key="'cancellation-' + $offer.gigId"
                :data-bid-id="$offer.gigId" 
                custom-class="cancelled-by-artist" 
                :text="artistCancellationText($offer)" 
                :to="'/gigs/' + $offer.gigId + '/bookings'"
              >
                <template #icon>
                  <ExclamationIcon />
                </template>
              </AlertWithLink>
            </transition-group>

            <transition-group 
              name="notification-list" 
              tag="div" 
              class="grid gap-4 content-start"
              v-if="notification_offers_needing_set_response.length"
            >
              <AlertWithLink 
                v-for="$offer in notification_offers_needing_set_response" 
                :key="'response-' + $offer.id"
                :data-bid-id="$offer.id" 
                color_class="red" 
                :text="$offer.venue.name + ' - ' + $offer.name + ' on ' + format($offer.start) + ' - artists available.  Click here to respond'" 
                :to="'/gigs/' + $offer.id + '/offers'"
              >
                <template #icon>
                  <BellIcon />
                </template>
              </AlertWithLink>
            </transition-group>

            <transition-group 
              name="notification-list" 
              tag="div" 
              class="grid gap-4 content-start"
              v-if="notification_offers_invoice_to_pay.length"
            >
              <AlertWithLink 
                v-for="$offer in notification_offers_invoice_to_pay" 
                :key="'invoice-' + $offer.id"
                :data-bid-id="$offer.id" 
                color_class="blue" 
                :text="$offer.venue.name + ' - ' + $offer.name + ' on ' + format($offer.start) + ' is ready for sign-off.'" 
                :to="'/past-gigs'"
              >
                <template #icon>
                  <ReceiptTaxIcon />
                </template>
              </AlertWithLink>
            </transition-group>
          </div>
        </div>
      </div>

      <div>
        <div class="content"></div>
      </div>
    </div>
    <div class="max-w-3xl grid grid-flow-row grid-cols-13 px-4 sm:px-6 "   v-if="progress && !progress.has_artists">
        <p v-if="!complete && !progress">You've still got steps to complete on your account.</p>
        <div class="col-span-6 pb-4 pt-8 text-center text-gray-500 flex flex-col ">
            <span class="text-5xl  text-gp_pink-default-500 mb-4 font-bold" >{{percentage}}%</span>
            Complete
        </div>
        <div class="col-start-9 col-end-14 flex justify-center items-center pb-4 pt-8">
            <nav aria-label="Progress">
            <ol role="list" class="flex items-center">
          
                <ProgressBarItem Step="One" :IsNextStep="true" :IsComplete="progress ? progress.has_venue : false" />
                <ProgressBarItem Step="Two" :IsNextStep="progress && progress.has_venue" :IsComplete="progress ? progress.has_templates : false" />
                <ProgressBarItem Step="Three" :IsNextStep="progress && progress.has_templates" :IsComplete="progress ? progress.has_gigs : false" />
                <ProgressBarItem Step="Four" :IsLast="true" :IsNextStep="progress && progress.has_gigs" :IsComplete="progress ? progress.has_artists : false" />
 
            </ol>
            </nav>
        </div>
    </div>
    <div class="max-w-7xl  px-4 sm:px-6 "  v-if="progress && !progress.has_artists">
      <!-- Replace with your content -->
      <div class="py-4">
        <div class="">
          <CompleteSteps :progress="progress" :complete="complete" />
        </div>
      </div>
      <!-- /End replace -->
    </div>
    <FailedPaymentsRyft />
    <PendingSubscriptionsRyft />
  </div>
</template>

<script>
// @ is an alias to /src

import { apiMethods, apiComputed } from '@/state/helpers'
import CompleteSteps from "../components/dashboard/CompleteSteps.vue";
import ProgressBarItem from '../components/dashboard/ProgressBarItem.vue';
import AlertWithLink from '../components/alert/AlertWithLink.vue';
import {BellIcon, ReceiptTaxIcon} from '@vue-hero-icons/outline';
import { ExclamationIcon } from "@vue-hero-icons/outline";
import FailedPaymentsRyft from '../components/modal/venues/subscriptions/FailedPaymentsRyft.vue';
import PendingSubscriptionsRyft from '../components/modal/venues/subscriptions/PendingSubscriptionsRyft.vue'
import format from '../utils/format-date-default';
import NProgress from 'nprogress';
import Modal from '../components/Modal.vue';
import { mapActions } from 'vuex';
import { debounce } from 'lodash';

export default {
  name: "Home",
  components: {
    CompleteSteps,
    ProgressBarItem,
    AlertWithLink,
    BellIcon,
    ReceiptTaxIcon,
    ExclamationIcon,
    FailedPaymentsRyft,
    PendingSubscriptionsRyft,
    Modal
},
  methods: {
		...mapActions('account', ['getUserInfo']),
    ...apiMethods,
    format,
    async loadDashboardData() {
      try {
        this.isLoading = true;

        const now = Date.now();
        const shouldRefetch = !this.lastFetchTimestamp || 
                            (now - this.lastFetchTimestamp) > this.cacheTimeout ||
                            !this.notification_bids;

        if (shouldRefetch) {
          const dashboardData = await this.getDashboard();
          this.lastFetchTimestamp = now;
          this.notification_bids = dashboardData;
          this.notification_count = dashboardData.length;
          this.loadNotifications();
        }

      } catch (error) {
        console.error('Error loading dashboard data:', error);
      } finally {
        this.isLoading = false;
        NProgress.done();
      }
    },
    loadNotifications() {
      const venuesEnabled = process.env.VUE_APP_ENABLE_VENUE_FUNCTIONALITY;
       
      if (venuesEnabled === "false"){ 
        localStorage.setItem('initialRoute', 'artist');
        this.$global.switchRoute('artist');        
        this.$router.push('/artist/profiles');
        return;
      }

      this.notification_offers_needing_set_response = this.filteredOffersNeedingResponse;
      this.notification_offers_invoice_to_pay = this.filteredOffersInvoiceToPay;
      this.notification_offers_artist_cancellations = this.filteredArtistCancellations;
      this.no_notifications = !this.hasNotifications;
    },
    handleInstallPrompt(e) {
      e.preventDefault();
      this.deferredPrompt = e;
    },
    artistCancellationText($offer) {
      return $offer.venueName + ' - ' + $offer.gigName + ' on ' + format($offer.gigStart) + '. ' + $offer.artistName + ' has cancelled due to ' + $offer.reason;
    },
    close() {
      this.showModal = false;
    },
    // Add method to force refresh
    async refreshDashboard() {
      this.lastFetchTimestamp = null;
      await this.loadDashboardData();
    },
    getCachedResponses() {
      if (!this.cachedOffersNeedingResponse || this.lastFilterTimestamp !== this.lastFetchTimestamp) {
        this.cachedOffersNeedingResponse = this.notification_bids.filter(f => {
          return !f.is_past && 
                 f.bookings_count === 0 && 
                 f.status !== "CANCELLATION_REQUESTED_BY_ARTIST" &&
                 f.status !== "NEEDS_SIGNOFF";
        });
        this.lastFilterTimestamp = this.lastFetchTimestamp;
      }
      return this.cachedOffersNeedingResponse;
    },
    getCachedInvoices() {
      if (!this.cachedOffersInvoiceToPay || this.lastFilterTimestamp !== this.lastFetchTimestamp) {
        this.cachedOffersInvoiceToPay = this.notification_bids.filter(f => f.status === "NEEDS_SIGNOFF");
        this.lastFilterTimestamp = this.lastFetchTimestamp;
      }
      return this.cachedOffersInvoiceToPay;
    },
    getCachedCancellations() {
      if (!this.cachedArtistCancellations || this.lastFilterTimestamp !== this.lastFetchTimestamp) {
        const cancellations = [];
        this.notification_bids.forEach(f => {
          if (f.bids && Array.isArray(f.bids)) {
            f.bids.forEach(bid => {
              if (bid.artist_cancelled && !bid.cancelled && !f.is_past) {
                cancellations.push({
                  gigId: f.id,
                  gigName: f.name,
                  gigStart: f.start,
                  venueName: f.venue.name,
                  artistName: bid.artist.name,
                  reason: bid.cancellation_reason.reason === 'Other' ? 
                    `"${bid.cancellation_reason_other}"` : 
                    bid.cancellation_reason.display_reason
                });
              }
            });
          }
        });
        this.cachedArtistCancellations = cancellations;
        this.lastFilterTimestamp = this.lastFetchTimestamp;
      }
      return this.cachedArtistCancellations;
    },
    // Debounced refresh method
    debouncedRefresh: debounce(function() {
      this.refreshDashboard();
    }, 1000),
    clearCache() {
      this.cachedOffersNeedingResponse = null;
      this.cachedOffersInvoiceToPay = null;
      this.cachedArtistCancellations = null;
      this.lastFilterTimestamp = null;
    },
    handleModalDisplay() {
      let modalStore = window.localStorage.getItem('venue-payment-modal');
      let isPopupEnabled = process.env.VUE_APP_ENABLE_POPUP_MODAL === "true";
      
      if (
        isPopupEnabled && 
        !this.acc_info.is_genre &&
        ((this.acc_info.has_payg_monthly_venue && !this.acc_info.has_active_venue_subscription) || 
        (this.acc_info.has_active_legacy_payg && !this.acc_info.has_active_venue_subscription)) && 
        !(this.acc_info.has_active_legacy_subscription && !this.acc_info.has_active_legacy_payg)
      ) {
        if (!modalStore || (parseInt(modalStore) <= 4)) {
          this.showModal = true;
          let viewCount = parseInt(modalStore) || 0;
          window.localStorage.setItem('venue-payment-modal', viewCount + 1);
        }
      }
    },
    async loadDashboardDataIfNeeded() {
      const now = Date.now();
      const shouldRefetch = !this.lastFetchTimestamp || 
                          (now - this.lastFetchTimestamp) > this.cacheTimeout ||
                          !this.notification_bids;

      if (shouldRefetch) {
        const dashboardData = await this.getDashboard();
        this.lastFetchTimestamp = now;
        this.notification_bids = dashboardData;
        this.notification_count = dashboardData.length;
        this.loadNotifications();
      }
    },
  },
  async created() {  
    // Remove duplicate progress call from created hook
    // Progress will be loaded in mounted hook
  },
  data(){
    return {
      deferredPrompt: null,
      hasInstalledPromptListener: false,
      isLoading: true,
      progress: false,
      notification_offers_needing_set_response: [],
      notification_offers_invoice_to_pay: [],
      notification_offers_artist_cancellations: [],
      no_notifications: null,
      unreadMessages: [],
      notification_bids: null,
      notification_count: 0,
      showModal: false,
      acc_info: null,
      lastFetchTimestamp: null,
      lastFilterTimestamp: null,
      cachedOffersNeedingResponse: null,
      cachedOffersInvoiceToPay: null,
      cachedArtistCancellations: null,
      cacheTimeout: 30000 // 30 seconds cache
    };
  },
  computed:{
    ...apiComputed,
    complete() {
      return this.percentage == 100;
    },
    percentage() {
      if (!this.progress)
        return 'calculating...';
      let array = [this.progress.has_venue, this.progress.has_gigs, this.progress.has_templates, this.progress.has_artists] 
      let count = array.filter(Boolean).length;
      let percent = count * (100 / array.length); 
      return percent; 
    },
    formattedResponseCount() {
      const count = this.filteredOffersNeedingResponse.length;
      return count >= 10 ? '10+' : count;
    },
    formattedInvoiceCount() {
      const count = this.filteredOffersInvoiceToPay.length;
      return count >= 10 ? '10+' : count;
    },
    formattedCancellationCount() {
      const count = this.filteredArtistCancellations.length;
      return count >= 10 ? '10+' : count;
    },
    filteredOffersNeedingResponse() {
      if (!this.notification_bids || !Array.isArray(this.notification_bids)) return [];
      return this.getCachedResponses();
    },
    filteredOffersInvoiceToPay() {
      if (!this.notification_bids || !Array.isArray(this.notification_bids)) return [];
      return this.getCachedInvoices();
    },
    filteredArtistCancellations() {
      if (!this.notification_bids || !Array.isArray(this.notification_bids)) return [];
      return this.getCachedCancellations();
    },
    hasNotifications() {
      return this.filteredOffersNeedingResponse.length > 0 || 
             this.filteredOffersInvoiceToPay.length > 0 || 
             this.filteredArtistCancellations.length > 0;
    }
  },
 async mounted(){
  
    // Start loading indicator
    NProgress.start();
    this.isLoading = true;
    
    try {
      // Get cached user data from store if available
      const cachedUser = this.$store.state.user.userData;
      const cachedUserInfo = this.$store.state.account.userInfo;

      // Load only what's needed
      const requests = [];
      
      if (!cachedUserInfo) {
        requests.push(this.getUserInfo());
      }
      
      if (!cachedUser) {
        requests.push(this.$store.dispatch('user/getUsersData'));
      }
      
      requests.push(this.getUserEventProgress());

      const results = await Promise.all(requests);
      
      // Process results based on what was fetched
      this.acc_info = cachedUserInfo || results[0];
      this.user = cachedUser || (requests.length === 3 ? results[1] : results[0]);
      this.progress = results[requests.length - 1].data;

      // Early return if venue
      if (this.user.genre.is_genre_venue) {
        this.$router.push('/calendar');
        return;
      }

      // Handle modal display logic
      this.handleModalDisplay();

      // Load dashboard data if needed
      await this.loadDashboardDataIfNeeded();

    } catch (error) {
      console.error('Error loading dashboard:', error);
    } finally {
      this.isLoading = false;
      NProgress.done();
    }
  }
};
</script>

<style>
.notification-list-enter-active,
.notification-list-leave-active {
  transition: all 0.3s ease;
}
.notification-list-enter-from,
.notification-list-leave-to {
  opacity: 0;
  transform: translateX(-30px);
}
.cancelled-by-artist {
  --tw-text-opacity: 1;
  color: rgba(255, 255, 255, var(--tw-text-opacity));
  background-color: rgba(250, 121, 0, var(--tw-bg-opacity));
}
.cancelled-by-artist:hover {
  background-color: rgba(250, 121, 0, 0.7819502801120448);
}
</style>
