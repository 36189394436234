<template>
    <div v-if="modal_open" transition="fadein" class="fixed z-10 inset-0 overflow-y-auto duration-300 ease-in transition-all modal" ref="modal" aria-labelledby="modal-title" role="dialog" aria-modal="true">
        <div class="flex items-center justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
            <div class="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" aria-hidden="true">
            </div>
            <span class="hidden sm:inline-block sm:align-middle sm:h-screen" aria-hidden="true">&#8203;</span>
            <transition appear enter-active-class="animate__animated animate__fadeInUp" leave-active-class="animate__animated animate__fadeOutDown">        
                <div class="inline-block align-bottom bg-gp_black-default-500 border border-gp_pink-default-400 rounded-3xl px-4 pt-5 pb-4 text-left shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-lg sm:w-full sm:p-6">
                    <div class="grid grid-cols-1">
                        <div class="h-auto w-full">
                            <div class="flex justify-between">
                                <h3 class="block font-bold text-gp_pink-default-500 uppercase" id="modal-title">NO CREDITS AVAILABLE</h3>
                            </div>

                            <div class="mt-10">
                                <div>
                                    <p class="text-sm mt-2 text-gray-100 font-medium">
                                        Clicking the 'Purchase credit' button below will charge your default payment card <span class="text-gp_pink-default-500">£{{ tokenPrice }}</span> to proceed with this gig.
                                    </p> 
                                </div>
                            </div>

                            <div class="w-full mt-10">
                                <GPButton @click.native="purchase()"
                                                :disabled="isSubmitting"
                                                :loading="isSubmitting"
                                                color="pink"
                                                class="w-full justify-between"
                                >
                                    <span class="font-semibold">Purchase credit</span>
                                    <ArrowNarrowRightIcon stroke="white" class="w-6 h-6"/>
                                </GPButton> 
                            </div>
                        </div>
                    </div>
                </div>
            </transition>        
        </div>
    </div>
</template>

<script>
import GPButton from '../../../../components/ui/buttons/Button.vue';
import { ArrowNarrowRightIcon } from "@vue-hero-icons/outline"
import {paymentsMethods, apiMethods} from "@/state/helpers";
import NProgress from 'nprogress';

export default
{
    name: "QuickTopUpForGig", 
    data() {
        return {
            modal_open: false,
            isSubmitting: false,
            payload: {
                gig_id: null,
                card_id: null,
                cost_per_token: '0.00'
            },
            payment_log_id: null,
        }
    },
    components: {
        GPButton,
        ArrowNarrowRightIcon
    },
    async mounted() {
        this.selectedVenue = this.$store.state.account.venueInfo;
        await this.getPAYGTokenPrice().then(response => (this.payload.cost_per_token = response.data[0].price));
    }, 
    computed: {
        tokenPrice() {
            return (this.payload.cost_per_token * 1.20).toFixed(2);
        }
    },
    methods: {
        ...paymentsMethods,
        ...apiMethods,
        toggleModal() {
            this.modal_open = !this.modal_open;
        },
        purchase() {

            this.isSubmitting = true;
          
            this.addSinglePaygPaymentForGig(this.payload)
                .then((response) => {
                    this.payment_log_id = response.payment_log_id;
                    this.tokenPaidCheck();
                })
                .catch(() => {
                    this.$notify("Payment failed, please try again or contact support for further assistance.");
                    this.isSubmitting = false;
                })

        },
        tokenPaidCheck() {

            NProgress.start();

            setTimeout(() => {
                this.checkTokensFullyPaid({ payment_log_id: this.payment_log_id })
                    .then((response) => {
                        if (response.data.length !== 1) {
                            this.tokenPaidCheck();
                        } else {
                            this.$notify("Payment successful.");
                            this.getUserAccInfo().then(response => this.$store.dispatch('account/updateAccInfo', response))
                            this.selectedVenue.credits_remaining = this.selectedVenue.credits_remaining + 1;
                            this.$store.dispatch('account/updateVenueInfo', this.selectedVenue)
                            this.isSubmitting = false;
                            this.$emit('resendGig');
                            this.toggleModal();

                            try
                            {
                                setTimeout(() => {
                                    window.dataLayer = window.dataLayer || []; //gtm ppc tracking
                                    window.dataLayer.push({'event': 'payg_purchase'});
                                    if (window.f24){
                                        let resp = window.f24('event', 'wa_page_view', { loc:window.location.origin, CheckOutAmount: 15});
                                        console.log('force24 tracking response:', resp);
                                    }
                                }, 2000);   
                            } catch (error){
                                console.error('Error tracking GTM/force24:', error);
                            }                         
                        }
                    })
                    .catch(() => {
                        this.$notify("An error has occurred. Please try again or contact support for further assistance.");
                        this.isSubmitting = false;
                    })
            }, 2000);
        },
        openWithInfo(info) {

            this.payload.gig_id = info.gig_id;

            this.getVenueDefaultPaymentCard({ venue_id: info.venue_id })
                .then((response) => {
                    this.payload.card_id = response.data.id;
                    this.toggleModal();
                });
        }
    }
}

</script>