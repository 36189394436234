import { mapState, mapGetters, mapActions } from 'vuex'

export const apiComputed = {
    ...mapState('api', {
        //
    }),
    //these are called upon computed
    ...mapGetters('api', [
        'regions',
        'venueTags', 
        'venues',
        'gigs',
        'gigtemplates',
        'artisttags',
        'artistbids',
        'uuid',
        'tiers',
        'artistdirectory',
        'faveslists',
        'artists',
        'gigbids'
    ])
}

export const authComputed = {
    ...mapState('auth', {
        //
    }),
    ...mapGetters('auth', [
        'currentUser'
    ])
}

export const apiMethods = mapActions('api', [
    'getDashboard',
    'setRegions',
    'getVenueTags',
    'getPrivateArtistVenues',
    'setVenueTags',
    'setArtistTags',
    'getUserEventProgress', 
    'getArtistDirectory',
    'getArtistDirectorySimple', 
    'getArtistWithSpecialities',
    'getGigs',
    'getGigDetails',
    'getGigCancellationReasons',
    'requestBookingCancellation',
    'getGigsMessages',
    'getHeardAboutUsOptions',
    'storeUserHeardAboutUsOption',
    'switchVenueFromStripeToRyft',
    'switchVenueToPAYGMonthly',
    'getGigLocations',
    'getGigMessages',
    'getGigChosenArtists',
    'getGigWithNotes',
    'getFutureGigs',
    'getInvoices',
    'getInvoice',
    'getFilteredInvoices',
    'getFilteredMonthlyInvoices',
    'archiveInvoice',
    'archiveAllSelected',
    'archiveAllSelectedMonthly',
    'getPendingGigs',
    'deleteArtistProfile',
    'skipArtistRating',
    'submitArtistRating',
    'skipAllArtistRatings',
    'getPastGigs',
    'getGigsByRange',
    'getPaymentModalVenueInfo',
    'getGigsCalendarByRange',
    'getGigWithOffers',
    'getGigBookings',
    'getGigDetailsWithOffers',
    'getGigBidsWithOffers',
    'getGig',
    'getTiers',
    'getArtistSignupExcludables',
    'getArtistTags',
    'getUserAccInfo',
    'getVenueCreatedByOnboard',
    'updateGig',
    'createGig',
    'deleteGig',
    'getGigTemplates',
    'createGigTemplate',
    'updateGigTemplate',
    'applyGigTemplate',
    'createGigRepeats',
    'markInvoiceAsPaid',
    'getVenues',
    'getVenue',
    'createNote',
    'updateNote',
    'deleteNote',
    'createVenue',
    'updateVenue',
    'setVenueIdentity',
    'setVenueContactDetails',
    'claimProfile',
    'loadAllPublicArtist',
    'createArtist',
    'createBrand',
    'getArtists',
    'getArtist',
    'getBrands',
    'getCountries',
    'getCurrencies',
    'updateArtist',
    'getNewArtistOffers',
    'getArtistRespondedOffers',
    'getFutureBookedOffers',
    'getArtistBids',
    'getArtistBid',
    'getGigBids',
    'initialArtistAcceptance',
    'multiInitialArtistAcceptance',
    'multiInitialArtistDecline',
    'updateArtistBid',
    'acceptFinal',
    'declineArtistFinal',
    'acceptAvailability',
    'declineAvailability',
    'updateGigBid',
    'signoffArtists',
    'createFave',
    'deleteFave',
    'getFavesLists',
    'createFavesList',
    'updateFavesList',
    'deleteFavesList',
    'deleteFavesListArtist',
    'deleteGigTemplate',
    'sendMessage',
    'getMessages',
    'markAsRead',
    'getArtistUnreadMessages',
    'getVenueUnreadMessages',
    'createRyftSubscription',
    'loadStripeIntent',
    'savePaymentMethod',
    'loadPaymentMethods',
    'removePaymentMethod',
    'updateSubscription',
    'testBroadcast',
    'authCallback',
    'bookingExport',
    'bookingExportMonthly',
    'getPrivateVenueRequests',
    'saveVenuePrivateArtists',
    'getPrivateVenueRequestsByArtist',
    'saveVenuePrivateArtist',
    'getDomainBrand',
    'getMonthlyInvoice',
    'getFaqs',
    'getMonthlyInvoices',
    'getUserNotificationsCount',
    'updateArtistAccountCode',
    'loginOpus',
    'getBasicAnalytics',
    'reactivateFreeTrial'
])

export const authMethods = mapActions('auth', [
    'setCurrentUser',
    'getArtistBids',
    'getArtists',
    'getArtistBid',
    'getGigBids',
    'updateArtistBid',
    'updateGigBid',
    'sendMessage',
    'getMessages',
    'markAsRead',
    'loadStripeIntent',
    'savePaymentMethod',
    'loadPaymentMethods',
    'removePaymentMethod',
    'updateSubscription',
    'testBroadcast',
    'authCallback'
    
])

export const venueMethods = mapActions('venue', [
    'getVenuesList',
    'getVenuesCalendarList',
    'getVenuesCurrencyList'
])

export const pastGigsMethods = mapActions('pastGigs', [
    'getPastGigsWithTrashed',
    'getArtistCancellationsWithTrashed'
])

export const paymentsMethods = mapActions('payments', [
    'createMerchantPaymentSession',
    'createMerchantCustomer',
    'createPaymentCard',
    'createPaymentCardFromOnboard',
    'createMerchantPaymentSessionFromOnboard',
    'addPayg',
    'addPaygMonthly',
    'addSinglePaygPaymentForGig',
    'addSubscription',
    'getSubscriptionPlans',
    'getPAYGTokenPrice',
    'updateVenueBillingDetails',
    'getVenuePaymentCards',
    'getVenuePaymentCardsNotExpired',
    'getVenueSubscriptionsInvoices',
    'useVenueTokenForGigBooking',
    'reinstateGigToken',
    'getVenueActiveSub',
    'getVenuePaymentCard',
    'checkTokensFullyPaid',
    'getFailedPaymentsLogs',
    'clearVenueFailedPayments',
    'getRyftVenueList',
    'getVenueActiveTokens',
    'getVenueDefaultPaymentCard',
    'checkFullyPaidSuccess',
    'deletePaymentCard',
    'updateCardToDefault',
    'checkIfPaidViaStripe',
    'getGigListCreditUsed',
    'getRyftVenuePendingSubscriptions',
    'activateRyftVenuePendingSubscription'
]);
