<template>
    <div class="navmenu relative  flex-shrink-0 flex h-16 bg-white shadow "  v-click-outside="closeMenu">
      <button @click="toggleMobile" type="button" class="px-4 border-r border-gray-200 text-gray-500 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-indigo-500 md:hidden">
        <span class="sr-only">Open sidebar</span>
        <!-- Heroicon name: outline/menu-alt-2 -->
        <svg class="h-6 w-6" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor" aria-hidden="true">
          <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M4 6h16M4 12h16M4 18h7" />
        </svg>
      </button>
      <div class="flex-1 px-4 flex justify-between">

        <div v-if="venue_info && venue_info.payment_process == 'ryft' && onEditOrGigRoute && !$route.path.includes('/add')" class="py-2 w-auto flex space-x-3">
          <div  class="hidden lg:flex xl:space-x-12  items-center xl:bg-gp_black-default-500  px-4 rounded-md text-white">
            <div>
              <p v-if="venue_info" class="hidden xl:block text-sm font-medium">{{ venue_info.name }}</p>
            </div>
            
            <div class="h-full space-x-3 hidden sm:flex items-center bg-gp_black-default-500  px-4 rounded-md text-white">
              <DatabaseIcon class="w-5 h-5" />
              <p v-if="venue_info" class="text-sm">{{ venue_info.credits_remaining }} credit<span v-show="venue_info.credits_remaining !== 1">s</span></p>
            </div>

            <div v-if="venue_info && !venue_info.has_subscription" @click="openTopUpModal()" class="space-x-3 sm:ml-2 flex items-center bg-gp_pink-default-500 hover:bg-gp_pink-default-400 px-4 py-3.5 xl:py-2 rounded-md text-white cursor-pointer">
              <DatabaseIcon class="w-5 h-5" />
                <span v-if="venue_info && venue_info.payment_type === 'payg'" class="text-sm">Add credit now</span>
                <span v-if="venue_info && venue_info.payment_type !== 'payg'" class="text-sm">Add subscription</span>
            </div>
          </div>
        </div>

        <div v-if="venue_info && venue_info.payment_process == 'ryft' && onEditOrGigRoute && !$route.path.includes('/add') && (cardExpiryCheck() || cardExpiredCheck())" class="hidden xl:flex py-2 ml-6 w-auto space-x-3">
          <div class="hidden lg:flex xl:space-x-6  items-center xl:bg-gp_black-default-500  px-4 rounded-md text-white">
              <CreditCardIcon stroke="white" class="w-5 h-6"/>
              <span v-show="cardExpiryCheck()" class="font-normal uppercase text-xs">Card Expires Soon!</span>
              <span v-show="cardExpiredCheck()" class="font-normal uppercase text-xs">Card Expired!</span>

              <div @click="addNewCard()" class="space-x-3 flex items-center bg-gp_pink-default-500 hover:bg-gp_pink-default-400 px-4 py-4 xl:py-2 pl-6 rounded-md text-white cursor-pointer">
                <span class="text-sm">Add new card</span>
              </div>
          </div>
        </div>
      
        <div v-if="showBanner && !isShowingNonSub" class="py-2 w-auto flex space-x-3">
          <div class="h-full space-x-3 hidden sm:flex items-center bg-gp_black-default-500 px-4 rounded-md text-white">
            <DatabaseIcon class="w-5 h-5" />
            <p class="text-sm">{{ returnCredits }}</p>
          </div>

          <div  class="hidden lg:flex xl:space-x-12  items-center xl:bg-gp_black-default-500 px-4 rounded-md text-white">
            <div>
              <p v-show="acc_info && acc_info.free_trial_info && acc_info.has_active_free_trial && trialDaysRemaining" class="hidden xl:block text-sm">{{ trialDaysRemaining }} day<span v-show="trialDaysRemaining && trialDaysRemaining !== 1">s</span> of your trial remaining before you lose access to our PAYG features</p>
              <p v-show="acc_info && acc_info.free_trial_info && !acc_info.has_active_free_trial" class="hidden xl:block text-sm">Your free trial is over.  To access the full GigPig Experience click here to top-up</p>
            </div>
            <div v-show="acc_info && acc_info.free_trial_info && !acc_info.has_active_legacy_subscription" @click="openTopUpModalFromTrialBanner()" class="space-x-3 flex items-center bg-gp_pink-default-500 hover:bg-gp_pink-default-400 px-4 py-4 xl:py-2 rounded-md text-white cursor-pointer">
              <DatabaseIcon class="w-5 h-5" />
              <span class="text-sm">Add credit</span>
            </div>
          </div>
        </div>

        <div class="flex-1 items-center hidden md:block invisible ">
          <p class="page_name" v-if="$route.meta.title">
            {{ $route.meta.title }} 
          </p>
        </div>

        <div class="ml-4 flex items-center md:ml-6">
          <button  @click="toggle()" type="button" class="bg-white hidden p-1 rounded-full text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500">
            <span class="sr-only">View notifications</span>
            <!-- Heroicon name: outline/bell -->
<svg xmlns="http://www.w3.org/2000/svg" class="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
  <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M16 7a4 4 0 11-8 0 4 4 0 018 0zM12 14a7 7 0 00-7 7h14a7 7 0 00-7-7z" />
</svg>
          </button>

          <!-- Profile dropdown -->
          <div class="ml-3 relative">
            <div>
              <button type="button" class="max-w-xs bg-white flex items-center text-sm rounded-full focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500" id="user-menu-button" aria-expanded="false" aria-haspopup="true">
                <span class="sr-only">Open user menu</span>
                <img  v-if="$auth.loggedIn && $auth.user" class="h-8 w-8 rounded-full" v-bind:src="$auth.user.picture" alt="">
              </button>
            </div>

            <!--
              Dropdown menu, show/hide based on menu state.

              Entering: "transition ease-out duration-100"
                From: "transform opacity-0 scale-95"
                To: "transform opacity-100 scale-100"
              Leaving: "transition ease-in duration-75"
                From: "transform opacity-100 scale-100"
                To: "transform opacity-0 scale-95"
            -->
            <div hidden  :class="{ 'active' : isNotificationMenuOpen, 'hidden' : !isNotificationMenuOpen }"
                    @click.prevent="toggle"
          class="z-50 origin-top-right absolute right-0 mt-2 w-48 rounded-md shadow-lg py-1 bg-white ring-1 ring-black ring-opacity-5 focus:outline-none" role="menu" aria-orientation="vertical" aria-labelledby="user-menu-button" tabindex="-1">
              <!-- Active: "bg-gray-100", Not Active: "" -->
               
              <router-link title="coming soon" v-if="$auth.isAuthenticated" to="#" class="cursor-not-allowed block px-4 py-2 text-sm text-gray-700" role="menuitem" tabindex="-1" id="user-menu-item-0"></router-link>

              <a href="#"  title="coming soon" class="block px-4 py-2 text-sm text-gray-700 cursor-not-allowed" role="menuitem" tabindex="-1" id="user-menu-item-1">Settings</a>

              <a  v-if="$auth.isAuthenticated" @click="$auth.logout()"  class="block px-4 py-2 text-sm text-gray-700 hovee:font-bold" role="menuitem" tabindex="-1" id="user-menu-item-2">Sign out</a>
                
              <a v-else @click="login()" class="block px-4 py-2 text-sm text-gray-700" role="menuitem" tabindex="-1" id="user-menu-item-2">Log In / Register</a>
            </div>
            
          </div>
              <div class="flex gap-2 items-center font-bold">
                <img  v-if="$auth && $auth.user && $auth.user.picture" class="h-8 w-8 rounded-full" v-bind:src="$auth.user.picture" alt="">
                
                <span v-if="$auth && $auth.user && $auth.user.name" class="mr-4 text-sm">{{$auth.user.name}}</span>
              </div>
          </div>
      </div>
      <TopUpOptions ref="topupmodal" />
      <SubscriptionUpgrade ref="subupgrademodal" />
      <SaveCard ref="savecardmodal" />
    </div>
</template>

<script>
import { mapGetters } from 'vuex';
import { DatabaseIcon, CreditCardIcon } from "@vue-hero-icons/outline"
import {layoutMethods} from '@/state/modules/layout.js'
import TopUpOptions from '../../components/modal/venues/subscriptions/TopUpOptions.vue'
import SaveCard from '../../components/modal/venues/subscriptions/SaveCard.vue'
import SubscriptionUpgrade from '../../components/modal/venues/subscriptions/SubscriptionUpgrade.vue'

export default {
    name: "NavHeader",
    components:{ 
      DatabaseIcon, CreditCardIcon,
      TopUpOptions,
      SaveCard,
      SubscriptionUpgrade
    },
    data() {
      return {
        isNotificationMenuOpen: false,
        acc_info: null,
        venue_info: null,
      }
    },
    watch: {
      $route(to, from) {
        if ((from.path.includes('/venues/') && !to.path.includes('/venues/')) || 
            (from.path.includes('/gigs/') && !to.path.includes('/gigs/'))) 
        {
          this.$store.dispatch('account/updateVenueInfo', null);
        }
      },
      getAccInfo: {
        immediate: true,
        handler(newValue) {
          this.acc_info = newValue;
        }
      },
      getVenueInfo: {
        immediate: true,
        handler(newValue) {
          this.venue_info = newValue;  
        }
      }
    },
    computed: {
      ...mapGetters('account', ['getAccInfo', 'getVenueInfo']),
      showBanner() {
        return this.acc_info && this.acc_info.has_active_free_trial && this.acc_info.onboarded_using_new_onboard && !this.acc_info.has_enterprise_venue && this.acc_info.venue_count == 1 && !this.acc_info.has_active_venue_subscription && !this.acc_info.has_active_payg_tokens
      },
      isShowingNonSub() {
        return this.$route.path.includes('/gigs') || this.$route.path.includes('/venues')
      },
      onEditOrGigRoute() {
        return this.$route.path.includes('/venues/') || this.$route.path.includes('/gigs/')
      },
      trialDaysRemaining() {
        if (this.acc_info && this.acc_info.free_trial_info && this.acc_info.free_trial_info.end_date) {
          const currentDate = new Date(); 
          const targetDate = new Date(this.acc_info.free_trial_info.end_date);
          const timeDiff = targetDate - currentDate;
          const daysDiff = timeDiff / (1000 * 60 * 60 * 24);

          return Math.floor(daysDiff);
        } else {
          return false
        }
      },
      isPastTrialDate() {
        if (this.acc_info && this.acc_info.free_trial_info && this.acc_info.free_trial_info.end_date) {
          const currentDate = new Date(); 
          const targetDate = new Date(this.acc_info.free_trial_info.end_date);
          return currentDate > targetDate;
        } else {
          return false;
        }
      },
      returnCredits() {
        return this.acc_info && !this.acc_info.free_trial_info ? '0 credits' : this.acc_info && this.acc_info.free_trial_info && this.acc_info.free_trial_info.credits_remaining == 0 ? '0 credits' : '1 credit'
      },
    },
    methods: {
      ...layoutMethods,
      openTopUpModal() {
        if (this.venue_info.payment_type === 'payg') {
          this.$refs.topupmodal.openWithVenueInfo({ id: this.venue_info.id });
        } else {
          this.$refs.subupgrademodal.openWithVenueInfo({ id: this.venue_info.id });
        }
      },
      openTopUpModalFromTrialBanner() {        
        let payment_type = this.acc_info.free_trial_info.payg_charged_and_billed_monthly == true ? 'payg_charged_and_billed_monthly' : 'payg';

        if (payment_type === 'payg') {
          this.$refs.topupmodal.openWithVenueInfo({ id: this.acc_info.free_trial_info.venue_id });
        } else {
          this.$refs.subupgrademodal.openWithVenueInfo({ id: this.acc_info.free_trial_info.venue_id });
        }
      },
      addNewCard() {
        this.$refs.savecardmodal.addVenueInfo({ id: this.venue_info.id , name: this.venue_info.name, currency_id: this.venue_info.currency_id, close_modal: false });
      },
      toggleMobile()
      {  
        this.setToggleMobileMenu(true);
      },
      toggle() {
          this.isNotificationMenuOpen = !this.isNotificationMenuOpen
      },
      closeMenu() {
          this.isNotificationMenuOpen = false;
      },
      cardExpiryCheck() {

        if (this.venue_info && this.venue_info.expiry_year && this.venue_info.expiry_month) {

          // if venue payment card expires within the next 60 days then show reminder otherwise dont show

          const currentDate = new Date();
          const expiryDate = new Date(parseInt(this.venue_info.expiry_year), parseInt(this.venue_info.expiry_month), 0);
          const diffInTime = expiryDate.getTime() - currentDate.getTime();
          const diffInDays = Math.ceil(diffInTime / (1000 * 60 * 60 * 24));

          return diffInDays > 0 && diffInDays < 60 ? true : false;

        } else {
          return false;
        }

      },
      cardExpiredCheck() {

        if (this.venue_info && this.venue_info.expiry_year && this.venue_info.expiry_month) {

          const currentDate = new Date();
          const expiryDate = new Date(parseInt(this.venue_info.expiry_year), parseInt(this.venue_info.expiry_month), 0);
          const diffInTime = expiryDate.getTime() - currentDate.getTime();
          const diffInDays = Math.ceil(diffInTime / (1000 * 60 * 60 * 24));

          return diffInDays < 1 ? true : false;

        } else {
          return false;
        }
      },
    },
}
</script>